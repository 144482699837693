import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ product }) => {
    if (!product) return null;
    return (
        <div className='container'>
            <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to={`/${product.type}`}>{product.type}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{product.plant_name}</li>
                </ol>
            </nav>
        </div>
    );
}

export default Breadcrumb;
