import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import HomeCom from './Components/Pages/HomeCom';
import IndoorCom from './Components/Pages/IndoorCom';
import OutdoorCom from './Components/Pages/OutdoorCom';
import Product from './Components/Product/Product';
import Footer from './Components/Footer/Footer';
import Error from './Components/Error/Error';
import Plants from './Components/Pages/Plants';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="*" exact element={<Error />} />
          <Route path="/" exact element={<HomeCom />} />
          <Route path='/indoor/' element={<IndoorCom />} />
          <Route path='/outdoor/' element={<OutdoorCom />} />
          {/* <Route path='/upcomming/' element={<Plants />} /> */}
          <Route path="/plant/:type/:slug" element={<Product />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;