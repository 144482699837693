import React, { useState } from 'react'
import logo from '../../Assets/media/logo.dbd161efe502f7f29192.png'
import { Link } from 'react-router-dom'
import './Navbar.css'



const Navbar = () => {
    return (
        <div>
            <nav className="navbar sticky-top bg-dark border-bottom border-body" data-bs-theme="dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Logo" width="30" height="24" className="d-inline-block align-text-top" />
                        Matoshri Nursery
                    </Link>
                    <div class="d-flex" role="search">
                    <Link class="nav-link" type="submit" to="https://forms.gle/3LTtVwwu3DFAZxS3A" target='_blank'>Feedback</Link>
      </div>
                </div>
                
            </nav>
            <ul className="nav navf nav-underline justify-content-center p-4">
                <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to='/'>Home</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to='/indoor'>Indoor Plants</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to='/outdoor'>Outdoor Plants</Link>
                </li>
                {/* <li className="nav-item"> 
                    <Link className="nav-link" to='/upcomming'>Upcomming Plants <span class="badge text-bg-success">New</span></Link>
                </li> */}
                {/* <li className="nav-item"> 
                    <Link className="nav-link" to='/test'>Test Components</Link>
                </li> */}
            </ul>
            <br />
        </div>
    )
}

export default Navbar
