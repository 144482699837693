import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear()
  return (
    <div>
        <br /><br />
      <footer>
        <p>
        CopyRight @{year} <a href='http://mindscapetechnologies.in/' target='_blank' >MindScape Technologies</a>
        </p>
       
      </footer>
    </div>
  )
}

export default Footer
