import Begonia from './media/Begonia.6307c0410c0b98ffb6cf.png'
import Cranesbill from './media/Cranesbill.c410eaced5d6afacab5e.png'
import Japanese from './media/Japanese Forest Grass.72348df673596600bbde.png'
import Lenten from "./media/Lenten Rose.fa6bf39e00b8bd399408.png"
import Lobelia from "./media/Lobelia.822efd844618efac8d8a.png"
import Lilac from "./media/Lilac Sage.608c1edc941d49f91aee.png"
import Butterfly from './media/Butterfly Weed.c7f62eb2b4a8cf5ae2af.png'
import Threadleaf from './media/Threadleaf Tickseed.a0a1dbd6702909ef8205.png'
import Shrubby from './media/Shrubby.ca51afa8d13e4dcb6e96.png'
import Dianthus from './media/Dianthus.ee3a1bd2aaa9076a2126.png'
import Armeria from './media/Armeria.8e885eb667196d0e1ba0.png'
import Sedum from './media/Sedum.09023c5c5194ca2077d5.png'
import Desert from './media/Desert Rose.a8897a339c5b49f18d51.png'
import Agave from './media/Agave.8975528e7c566e1cb786.png'
import Carex from './media/Carex Pensylvanica.91123111f1fb3bdbff55.png'
import First from './media/First Frost Hosta.2a5116c167e000e55aef.png'
import Oakleaf from './media/Oakleaf Hydrangea.4178c8ec22ab01face24.png'
import Persian from './media/Persian Shield.5f25557fb720052b4f9b.png'
import Caladium from './media/Caladium.159fc19f8f658cc92423.png'
import Ajuga from './media/Ajuga.0862df77978855ff0203.png'


const OutDoor = [
    {
        id: 1,
        plant_name: "Ajuga",
        image: Ajuga,
        type: "outdoor",
        long_description: "If your backyard doesn't get much sun throughout the day, it can be tough to find attractive plants that will thrive in the shadows. To find the best shade-loving plants around, we asked Justin Hancock, a horticulturist at Costa Farms, for the company's top picks. Headquartered in Miami, Florida, Costa Farms supplies houseplants and garden plants to retailers and garden centers across the country, so the team is well-versed in selecting plants that will survive in real backyards. The shade-surviving plant at the top of the list: ajuga. This weed-smothering groundcover is a great pick if you're looking to add color and decrease maintenance in shaded areas of your backyard. It offers adorable spikes of blue-purple flowers in spring, then a mat of dense foliage the rest of the gardening season, Hancock says. To up the visual interest, look for variegated selections, such as Burgundy Glow, which has shades of pink and silver in its leaves. Plus, ajuga comes back every year, so you can plant it once and enjoy it for years to come.",
        best_for: "Shady Backyards",
        description: "A weed-smothering groundcover with blue-purple flowers and dense foliage.",
        highlight: "Varieties like 'Burgundy Glow' offer shades of pink and silver.",
        care_tips: {
            light: "Prefers partial shade to full shade. Can tolerate some morning sun.",
            soil: "Well-draining soil enriched with organic matter.",
            water: "Keep soil consistently moist, especially during dry periods.",
            temperature: "Prefers cool to moderate temperatures.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Trim back flower spikes after blooming to promote foliage growth.",
                "Divide overcrowded plants in spring to rejuvenate growth."
            ]
        }
    },
    {
        id: 2,
        plant_name: "Caladium",
        image: Caladium,
        type: "outdoor",
        long_description: "Bring a touch of the tropics to your shaded backyard with the big, beautiful leaves of caladium, Hancock recommends. This plant's arrowhead-shaped leaves are readily found in shades of red, pink, and white, so you can coordinate this pick with the other plants in your garden. Costa Farm's favorite hue? The white varieties are particularly fun in the shade because they seem to glow, especially at dusk, Hancock says. You can treat caladium as an annual and leave it in the ground throughout the year, or you can dig it up and store it in a cool place for the winter.",
        best_for: "Shady Backyards",
        description: "Tropical plant with arrowhead-shaped leaves in red, pink, and white.",
        highlight: "White varieties glow in the shade.",
        care_tips: {
            light: "Prefers bright, indirect light. Avoid direct sun to prevent leaf scorch.",
            soil: "Well-draining soil with good moisture retention.",
            water: "Keep soil evenly moist but not waterlogged. Reduce watering in winter.",
            temperature: "Thrives in temperatures between 18-24°C (65-75°F).",
            humidity: "Prefers high humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Fertilize monthly during the growing season with balanced fertilizer.",
                "Remove any damaged or yellowing leaves to promote new growth."
            ]
        }
    },
    {
        id: 3,
        plant_name: "Persian Shield",
        image: Persian,
        type: "outdoor",
        long_description: "If you're searching for a failproof plant that can thrive in the north or the south, in the sun or the shade, in a garden bed, or a container garden, then the Persian Shield is for you. This colorful, leafy plant is more than just durable, it's also a true standout in the garden. We adore this tropical plant's metallic-purple foliage—it definitely makes a statement on its own or paired with other plants, Hancock says. Treat this tropical plant as an annual, or if you're adventurous, Hancock recommends bringing it indoors in the winter and keeping it in a bright spot.",
        best_for: "Versatile (shade or sun)",
        description: "Metallic-purple foliage, durable in various conditions.",
        highlight: "Can be an annual or indoor plant during winter.",
        care_tips: {
            light: "Prefers bright, indirect light. Can tolerate some morning sun.",
            soil: "Well-draining soil enriched with organic matter.",
            water: "Keep soil evenly moist, especially during active growth.",
            temperature: "Thrives in warm temperatures, around 20-30°C (68-86°F).",
            humidity: "Prefers moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Pinch back growing tips regularly to encourage bushier growth.",
                "Prune back leggy stems in spring to maintain compact shape."
            ]
        }
    },
    {
        id: 4,
        plant_name: "Oakleaf Hydrangea",
        image: Oakleaf,
        type: "outdoor",
        long_description: "To get even more shade-surviving selections, we turned to the plant pros at the Chicago Botanic Garden. Jacob Burns, the curator of herbaceous perennial plants, guided us through the varieties best suited for deep shade. Even when hidden beneath a conifer or tucked into the darkest corner of your yard, these plants are likely to thrive. One low-light pick at the top of Burns' list was the oakleaf hydrangea shrub. Bold, textural leaves and large cone-shaped flowers make this shrub an option with serious curb appeal for the front yard. These plants start blooming in mid-June, and they bloom well into the summer when a lot of other shrubs aren't, Burns explains. To enjoy blossoms throughout the season, opt for the oakleaf hydrangea.",
        best_for: "Deep shade",
        description: "Large cone-shaped flowers, bold textural leaves.",
        highlight: "Blooms from mid-June to summer.",
        care_tips: {
            light: "Prefers partial shade to full shade. Can tolerate some dappled sunlight.",
            soil: "Moist, well-draining soil rich in organic matter.",
            water: "Keep soil evenly moist, especially during hot periods.",
            temperature: "Thrives in cool to moderate temperatures.",
            humidity: "Tolerates varying humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Apply a layer of mulch around the base to retain soil moisture and regulate temperature.",
                "Prune back flowering stems after blooming to promote new growth."
            ]
        }
    },
    {
        id: 5,
        plant_name: "First Frost Hosta",
        image: First,
        type: "outdoor",
        long_description: "If you're dealing with a backyard that's both shady and dry, the hosta is one of the few plants that will be happy to call it home. Growing to about 16 inches tall and 30 inches wide, a group of hostas can help fill the space in a low-light garden. In fact, most hostas prefer some shade, and the darker the plant, the more likely it is to thrive in a dim environment. When planting in deep shade, Burns recommends planting the First Frost variety of hosta, which has pretty variegated leaves with pale lavender flowers.",
        best_for: "Shady, dry areas",
        description: "Variegated leaves with pale lavender flowers.",
        highlight: "Thrives in low-light conditions.",
        care_tips: {
            light: "Prefers partial shade to full shade. Avoid direct sun.",
            soil: "Moist, well-draining soil.",
            water: "Keep soil evenly moist, especially during dry spells.",
            temperature: "Cool to moderate temperatures are ideal.",
            humidity: "Tolerates varying humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Divide clumps every few years to rejuvenate growth and prevent overcrowding.",
                "Apply a balanced fertilizer in spring to support healthy foliage."
            ]
        }
    },
    {
        id: 6,
        plant_name: "Carex Pensylvanica",
        image: Carex,
        type: "outdoor",
        long_description: "This is a great alternative to grass for a shady spot, Burns says. This free-growing ground cover is similar to a soft carpet for your garden. Once planted, it will spread out over the yard, filling in any open areas without choking out the plants that already live there. It adds a softer texture and will spread out and mingle with the other plants, Burns explains, so you won't have to worry too much about it. Pair this shady plant with a flowering, low-light pick, such as hellebore, that will bring a pop of color to the lush green landscape.",
        best_for: "Shady Backyards",
        description: "Soft, carpet-like ground cover that spreads without choking out other plants.",
        highlight: "Pairs well with flowering low-light plants like hellebore.",
        care_tips: {
            light: "Prefers partial shade to full shade. Can tolerate some morning sun.",
            soil: "Moist, well-draining soil rich in organic matter.",
            water: "Keep soil consistently moist, especially during dry periods.",
            temperature: "Cool to moderate temperatures are ideal.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Trim back any dead or yellowing foliage to maintain a tidy appearance.",
                "Divide overcrowded clumps in early spring to rejuvenate growth."
            ]
        }
    },
    {
        id: 7,
        plant_name: "Agave",
        image: Agave,
        type: "outdoor",
        long_description: "If your yard happens to be situated in a bright and dry area, choosing plants that will thrive there can be just as difficult as finding shade-surviving varieties. One of Hancock's favorite picks for a drought-prone area is the agave, a low-water succulent that makes a statement anywhere you plant it. There are a wealth of varieties from which to choose. Our favorites are variegated types that bring in an extra splash of color, he suggests. Look for two-tone leaves with distinctive colorings, such as those with yellow borders and deep green centers, to up the visual interest.",
        best_for: "Drought-Prone Backyards",
        description: "Low-water succulent that thrives in bright, dry areas.",
        highlight: "Variegated types with distinctive two-tone leaves.",
        care_tips: {
            light: "Prefers full sun to partial shade. Thrives in bright, sunny locations.",
            soil: "Well-draining soil mix, sandy or rocky is preferable.",
            water: "Water sparingly, allowing soil to dry out completely between waterings.",
            temperature: "Prefers warm temperatures, but can tolerate some cold.",
            humidity: "Low humidity tolerant.",
            toxicity: "Can be toxic if ingested; keep away from pets and children.",
            tips: [
                "Protect from frost and extreme cold; bring indoors during freezing temperatures if possible.",
                "Remove dead or damaged leaves regularly to keep the plant healthy."
            ]
        }
    },
    {
        id: 8,
        plant_name: "Desert Rose",
        image: Desert,
        type: "outdoor",
        long_description: "Think all succulents are spiny? Think again! Hancock challenges us to reconsider what we think we know about these drought-tolerant plants. Desert rose looks like a bonsai with a thick, squat trunk and beautiful flamboyant blooms. Because its trunk stores water, you don't have to water it—even in times of drought, he says. Whether planting in the backyard or designing an indoor container backyard, pair this flowering plant with cacti or more classic spiky succulents for a desert-inspired garden that will survive even the hottest summer. Plant desert rose as an annual in the north, or treat it as perennial in frost-free regions.",
        best_for: "Drought-Prone Backyards",
        description: "Thick, squat trunk with flamboyant blooms and water-storing capabilities.",
        highlight: "Pairs well with cacti and other succulents.",
        care_tips: {
            light: "Prefers full sun to partial shade. Thrives in bright, sunny locations.",
            soil: "Well-draining soil mix, sandy or rocky is preferable.",
            water: "Allow soil to dry out completely between waterings. Water sparingly.",
            temperature: "Prefers warm temperatures; protect from frost and cold drafts.",
            humidity: "Low humidity tolerant.",
            toxicity: "Can be toxic if ingested; keep away from pets and children.",
            tips: [
                "Prune lightly after flowering to maintain shape and encourage new blooms.",
                "Fertilize sparingly during the growing season with a balanced fertilizer."
            ]
        }
    },
    {
        id: 9,
        plant_name: "Sedum",
        image: Sedum,
        type: "outdoor",
        long_description: "Even at the height of summer, this low-maintenance plant rarely needs to be watered. And because there is a seemingly endless variety of sedum available, ranging from low-growing groundcovers to upright bloomers, the best plant to pair beside sedum is, well, more sedum. To take the guesswork out of planting small individual plants, Hancock recommends buying a sedum tile (a living carpet made of a patchwork of smaller plants), often found at garden centers. To settle a sedum tile into its new environment, simply drop it onto loose soil, water well to start, and watch it grow.",
        best_for: "Drought-Prone Backyards",
        description: "Low-maintenance plant with a variety of forms, from groundcovers to upright bloomers.",
        highlight: "Sedum tiles create a 'living carpet' for easy planting.",
        care_tips: {
            light: "Prefers full sun to partial shade. Can tolerate full sun in cooler climates.",
            soil: "Well-draining soil, sandy or rocky is preferable.",
            water: "Water sparingly, allowing soil to dry out completely between waterings.",
            temperature: "Thrives in warm temperatures; protect from frost and cold drafts.",
            humidity: "Low humidity tolerant.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune back tall sedum varieties in early spring to encourage compact growth.",
                "Fertilize lightly with a balanced fertilizer in spring to promote flowering."
            ]
        }
    },
    {
        id: 10,
        plant_name: "Armeria",
        image: Armeria,
        type: "outdoor",
        long_description: "If you're lucky enough to have a yard that's neither shady nor dry, it may still face a third common gardening dilemma: sandy soil. If you're a seaside gardener searching for a plant that can survive not only sandy soil, but also salt sprays and not-so-gentle seaside breezes, low-growing armeria will shine in your beachside backyard. Cute and charming, this adorable little perennial has tufts of grassy foliage and globe-shaped flowers in shades of red, pink, and white throughout the spring, Hancock explains. Measuring just 6 to 10 inches tall, this ground-hugging plant seems to duck underneath the wind in blustery areas.",
        best_for: "Backyards With Sandy Soil",
        description: "Low-growing perennial with globe-shaped flowers in red, pink, and white.",
        highlight: "Adapts well to seaside conditions.",
        care_tips: {
            light: "Prefers full sun. Thrives in bright, sunny locations.",
            soil: "Well-draining sandy soil; tolerates salt and wind exposure.",
            water: "Water sparingly once established; avoid waterlogged conditions.",
            temperature: "Prefers moderate temperatures; protect from frost.",
            humidity: "Tolerates low humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Deadhead spent flowers to encourage continuous blooming.",
                "Mulch around the base to conserve moisture and protect roots."
            ]
        }
    },
    {
        id: 11,
        plant_name: "Dianthus",
        image: Dianthus,
        type: "outdoor",
        long_description: "Dianthus is one of those plants you may be able to smell before you see it, Hancock says. Especially on a warm day, this flowering plant's lovely fragrance will greet you long before you spot the beautiful blossoms in shades of purple, pink, white, and lavender. While some varieties of dianthus bloom in spring, others, such as Kahori, start in the spring and continue to flower all summer long. In addition to the spectacular blooms, the annual dianthus isn't afraid to show off its blue-green foliage, making it a welcome addition to garden beds or potted gardens.",
        best_for: "Backyards With Sandy Soil",
        description: "Fragrant flowering plant with blooms in shades of purple, pink, white, and lavender.",
        highlight: "Variety 'Kahori' blooms all summer long.",
        care_tips: {
            light: "Prefers full sun; can tolerate partial shade in hotter climates.",
            soil: "Well-drained sandy soil; amend with organic matter for better results.",
            water: "Regular watering; avoid waterlogged conditions.",
            temperature: "Prefers moderate temperatures; protect from frost.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Deadhead spent blooms regularly to encourage continuous flowering.",
                "Apply a balanced fertilizer in early spring to promote healthy growth."
            ]
        }
    },
    {
        id: 12,
        plant_name: "Shrubby Cinquefoil, 'Primrose Beauty'",
        image: Shrubby,
        type: "outdoor",
        long_description: "When selecting plants for sandy soil, Jacob Burns from the Chicago Botanic Garden leans toward low-maintenance options, such as Primrose Beauty, a type of flowering shrub that favors well-drained soil. Known for its pale yellow blooms, this variety fares best when situated in full sun. And the best part? You don't have to prune it, Burns reports. From June until September, the blooms come out in full force, but with a maximum height of around 3 feet, this shrub won't take over your garden and will play nicely with other plants.",
        best_for: "Backyards With Sandy Soil",
        description: "Flowering shrub with pale yellow blooms that favors well-drained soil.",
        highlight: "Blooms from June to September without requiring pruning.",
        care_tips: {
            light: "Prefers full sun; tolerates partial shade.",
            soil: "Well-drained sandy soil; amend with compost for better fertility.",
            water: "Regular watering during dry periods; avoid overwatering.",
            temperature: "Tolerates a wide range of temperatures; protect from extreme cold.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune lightly after flowering to maintain shape and encourage new growth.",
                "Apply a slow-release fertilizer in spring for optimal blooming."
            ]
        }
    },
    {
        id: 13,
        plant_name: "Threadleaf Tickseed",
        image: Threadleaf,
        type: "outdoor",
        long_description: "Bees and butterflies love this plant, Burns says, so if you're looking to attract winged beauties to your yard, this flowering plant will soon become your go-to variety. The bright golden flowers will bloom for over a month, from June to July, and they will continue to bloom into the fall if you deadhead the spent flowers. Considering that its blooms don't seem to mind a trim every now and then, this plant makes an excellent addition to a cutting garden.",
        best_for: "Backyards With Sandy Soil",
        description: "Bright golden flowers that bloom for over a month and attract bees and butterflies.",
        highlight: "Extended bloom period with regular deadheading.",
        care_tips: {
            light: "Prefers full sun; tolerates light shade.",
            soil: "Well-drained sandy soil; amend with organic matter for better nutrient retention.",
            water: "Regular watering during dry spells; avoid waterlogged conditions.",
            temperature: "Adaptable to various temperatures; protect from frost.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Deadhead spent flowers regularly to promote continuous blooming.",
                "Divide clumps every few years to rejuvenate growth."
            ]
        }
    },
    {
        id: 14,
        plant_name: "Butterfly Weed",
        image: Butterfly,
        type: "outdoor",
        long_description: "Burns suggests planting butterfly weed, not only because its vibrant orange blossoms will attract compliments, but also because it's the monarch butterfly host plant, and planting milkweed supports the species' survival. Monarch butterflies lay their eggs on the leaves of the milkweed plant since the leaves are the only source of food for monarch caterpillars. Native to North America, milkweed grows naturally almost everywhere across the country, except for parts of the Pacific Northwest, so it's adept at surviving not only sandy soil, but also the varied weather conditions across the country. Introduce this showy, no-fuss plant to your garden, and don't be surprised if it steals the show with its colorful flowers and entourage of butterflies.",
        best_for: "Backyards With Sandy Soil",
        description: "Vibrant orange blossoms that attract monarch butterflies and support their lifecycle.",
        highlight: "Native to North America and adept at surviving varied weather conditions.",
        care_tips: {
            light: "Full sun; tolerates partial shade.",
            soil: "Well-drained sandy soil; adaptable to various soil conditions.",
            water: "Moderate watering; avoid overwatering.",
            temperature: "Prefers warm temperatures; protect from frost.",
            humidity: "Tolerates low humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Plant in a location protected from strong winds to prevent damage.",
                "Cut back stems in late fall or early spring to encourage new growth."
            ]
        }
    },
    {
        id: 15,
        plant_name: "Lilac Sage, aka 'Purple Rain'",
        image: Lilac,
        type: "outdoor",
        long_description: "As a professional plant curator at the Chicago Botanic Garden, Jacob Burns is continually considering which plants will coordinate well with others. Luckily, lilac sage makes the matchmaking process remarkably easy. The purple color goes well with almost any other color in the garden, Burns explains. While well-drained, sandy soil typically provides a decent support system for these flowers, if they begin to droop under their own weight, Burns recommends cutting the stalks down and starting fresh. A relatively long bloom period, from July through September, will brighten up the garden for months, and deadheading faded blooms could help extend the season even longer.",
        best_for: "Backyards With Sandy Soil",
        description: "Purple flowers that coordinate well with other garden colors.",
        highlight: "Long bloom period from July to September.",
        care_tips: {
            light: "Full sun; tolerates partial shade.",
            soil: "Well-drained sandy soil; amend with compost for better fertility.",
            water: "Regular watering during dry periods; avoid overwatering.",
            temperature: "Adaptable to various temperatures; protect from frost.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Deadhead spent blooms regularly to prolong flowering.",
                "Prune back in early spring to encourage bushier growth."
            ]
        }
    },
    {
        id: 16,
        plant_name: "Lobelia",
        image: Lobelia,
        type: "outdoor",
        long_description: "With stunning purple flowers, Lobelia will add a pop of color to any shady garden. Lobelia prefers cool fall weather, hates direct sunlight, and wilts if the temperature exceeds 70°F. An easy-to-maintain plant, they look beautiful cascading out of planters alongside garden walls. If they do get a little too sad looking, just trim them back, and once the temperature drops, they’ll perk up.",
        best_for: "Shady Backyards",
        description: "Stunning purple flowers that prefer cool fall weather and shade.",
        highlight: "Beautiful cascading out of planters and garden walls.",
        care_tips: {
            light: "Prefers partial to full shade; avoid direct sunlight.",
            soil: "Well-drained soil enriched with organic matter.",
            water: "Regular watering; keep soil consistently moist but not waterlogged.",
            temperature: "Prefers cooler temperatures; protect from heatwaves.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Trim back leggy stems to encourage bushier growth.",
                "Apply a balanced fertilizer monthly during the growing season."
            ]
        }
    },
    {
        id: 17,
        plant_name: "Lenten Rose",
        image: Lenten,
        type: "outdoor",
        long_description: "If you want color in your shady garden, go for Lenten Rose. A variety of hellebore, these perennials come in a variety of bright and bold colors and are perfect for cooler climates and shady spots; they even bloom when snow is still on the ground!",
        best_for: "Shady Backyards",
        description: "Perennials with bright, bold flowers perfect for cooler climates.",
        highlight: "Bloom even when snow is still on the ground.",
        care_tips: {
            light: "Partial to full shade; protect from direct sunlight.",
            soil: "Rich, well-drained soil; amend with compost for better fertility.",
            water: "Regular watering; keep soil evenly moist.",
            temperature: "Cold-hardy; protect from extreme cold and frost.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Mulch around the plants to maintain soil moisture and protect roots.",
                "Divide clumps every few years to rejuvenate growth."
            ]
        }
    },
    {
        id: 18,
        plant_name: "Japanese Forest Grass",
        image: Japanese,
        type: "outdoor",
        long_description: "These shaggy pom-pom-like plants add a fun pop to any garden but are especially great for darker areas that don’t get direct sun. An ornamental grass, they thrive in the shade and grow in cute round clumps.",
        best_for: "Shady Backyards",
        description: "Ornamental grass that thrives in the shade and grows in round clumps.",
        highlight: "Adds a fun pop to darker areas.",
        care_tips: {
            light: "Partial to full shade; prefers indirect light.",
            soil: "Moist, well-drained soil; enrich with compost for optimal growth.",
            water: "Regular watering; keep soil consistently moist.",
            temperature: "Prefers cool to moderate temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Cut back dead foliage in late winter or early spring to promote new growth.",
                "Divide clumps every few years to prevent overcrowding."
            ]
        }
    },
    {
        id: 19,
        plant_name: "Cranesbill (Hardy Geranium)",
        image: Cranesbill,
        type: "outdoor",
        long_description: "Cranesbill, also called hardy geraniums, literally have hardy in the name. With pink and purple flowers, these hard-to-kill plants bloom from late spring through the end of summer, with some varieties blooming continuously. They can survive almost anything and still pop out a flower for you, perfect for low-maintenance gardens.",
        best_for: "Shady Backyards",
        description: "Pink and purple flowers that bloom continuously and are hard to kill.",
        highlight: "Survives almost anything and adds color to low-maintenance gardens.",
        care_tips: {
            light: "Partial to full shade; protect from intense afternoon sun.",
            soil: "Well-drained soil; amend with organic matter for better moisture retention.",
            water: "Regular watering; allow soil to dry slightly between waterings.",
            temperature: "Adaptable to a wide range of temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Deadhead spent flowers to encourage prolonged blooming.",
                "Divide plants every few years to rejuvenate growth."
            ]
        }
    },
    {
        id: 20,
        plant_name: "Begonia",
        image: Begonia,
        type: "outdoor",
        long_description: "If it's variety you’re looking for, look no further than begonias. With a ton of types to choose from, these flowers will thrive just about anywhere and come in a whole slew of colors. Most grow as annuals, can handle both sun and shade, and work for garden beds, pots, or anything really!",
        best_for: "Anywhere",
        description: "Variety of types that can handle both sun and shade, perfect for garden beds or pots.",
        highlight: "Comes in many colors and varieties, including adorable polka dot ones.",
        care_tips: {
            light: "Prefers filtered sunlight or partial shade; avoid full sun in hot climates.",
            soil: "Well-drained, fertile soil; enrich with compost for better growth.",
            water: "Regular watering; keep soil evenly moist.",
            temperature: "Prefers moderate temperatures; protect from extreme heat and cold drafts.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Pinch off spent blooms to encourage continuous flowering.",
                "Feed with a balanced fertilizer every 4-6 weeks during the growing season."
            ]
        }
    },
    {
        id: 21,
        plant_name: "Rose (Rosa spp.)",
        //  image: RoseImage, // Add your image reference here
        type: "outdoor",
        long_description: "Roses are classic flowering shrubs known for their elegant blooms and fragrance. They come in various colors and forms, from single to double petals, and can range in size from compact bushes to climbing vines.",
        best_for: "Ideal for garden borders, containers, or as cut flowers for bouquets.",
        description: "Elegant blooms and fragrance in various colors and forms.",
        highlight: "Continuous blooming throughout the growing season, diverse colors and fragrances.",
        care_tips: {
            light: "Full sun exposure.",
            soil: "Well-draining soil.",
            water: "Regular watering to keep soil evenly moist.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune regularly to promote new growth and flowering.",
                "Feed with a balanced fertilizer every 4-6 weeks during the growing season."
            ]
        }
    },
    {
        id: 22,
        plant_name: "Lavender (Lavandula spp.)",
        //   image: LavenderImage, // Add your image reference here
        type: "outdoor",
        long_description: "Lavender is a fragrant herb with narrow, aromatic leaves and spikes of purple, pink, or white flowers. It's prized for its soothing scent and medicinal properties.",
        best_for: "Perfect for herb gardens, rock gardens, or as a border plant.",
        description: "Fragrant herb with narrow, aromatic leaves and spikes of flowers.",
        highlight: "Attracts pollinators, drought-tolerant once established, and versatile culinary and medicinal herb.",
        care_tips: {
            light: "Full sun exposure.",
            soil: "Well-draining soil.",
            water: "Water sparingly; allow soil to dry out between waterings.",
            temperature: "Prefers moderate to warm temperatures.",
            humidity: "Tolerates low to moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune after flowering to maintain shape and encourage bushiness.",
                "Fertilize with a balanced fertilizer in spring."
            ]
        }
    },
    {
        id: 23,
        plant_name: "Sunflower (Helianthus annuus)",
        //  image: SunflowerImage, // Add your image reference here
        type: "outdoor",
        long_description: "Sunflowers are tall annual plants with large, bright yellow flowers and coarse, hairy leaves. They symbolize summer and are known for their cheerful appearance.",
        best_for: "Ideal for borders, backgrounds, or as a cut flower in arrangements.",
        description: "Tall annual plants with large, bright yellow flowers.",
        highlight: "Fast-growing, attracts birds and pollinators, edible seeds.",
        care_tips: {
            light: "Full sun exposure.",
            soil: "Fertile, well-draining soil.",
            water: "Water regularly; keep soil evenly moist.",
            temperature: "Prefers warm temperatures.",
            humidity: "Tolerates low to moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide support for tall varieties.",
                "Deadhead flowers to prolong blooming.",
                "Fertilize with a balanced fertilizer in spring."
            ]
        }
    },
    {
        id: 24,
        plant_name: "Geranium (Pelargonium spp.)",
        // image: GeraniumImage, // Add your image reference here
        type: "outdoor",
        long_description: "Geraniums are popular bedding plants with rounded leaves and clusters of flowers in various colors like red, pink, white, or purple. They are known for their long flowering period.",
        best_for: "Great for borders, hanging baskets, or containers.",
        description: "Popular bedding plants with rounded leaves and clusters of flowers.",
        highlight: "Low-maintenance, drought-tolerant, continuous blooms.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Water regularly; allow the top inch of soil to dry out between waterings.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Tolerates low to moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Deadhead spent flowers to encourage new blooms.",
                "Overwinter indoors in cooler climates.",
                "Fertilize with a balanced fertilizer every 4-6 weeks during the growing season."
            ]
        }
    },
    {
        id: 25,
        plant_name: "Hosta (Hosta spp.)",
        //   image: HostaImage, // Add your image reference here
        type: "outdoor",
        long_description: "Hostas are perennial plants with large, ribbed leaves that come in shades of green, blue, or variegated patterns. They produce spikes of flowers in summer.",
        best_for: "Perfect for shady areas, borders, or as ground cover.",
        description: "Perennial plants with large, ribbed leaves in various shades.",
        highlight: "Low-maintenance, foliage interest, slug-resistant (certain varieties).",
        care_tips: {
            light: "Partial to full shade.",
            soil: "Fertile, moist, well-draining soil.",
            water: "Keep soil evenly moist.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Tolerates moderate to high humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Divide plants every few years to maintain vigor.",
                "Mulch around plants to retain moisture.",
                "Fertilize with a balanced fertilizer in spring."
            ]
        }
    }
    ,
    {
        id: 26,
        plant_name: "Hydrangea (Hydrangea spp.)",
        //   image: HydrangeaImage, // Add your image reference here
        type: "outdoor",
        long_description: "Hydrangeas are deciduous shrubs known for their large clusters of flowers in shades of pink, blue, white, or purple, depending on soil acidity. They have broad, toothed leaves.",
        best_for: "Great as a focal point, in borders, or as cut flowers.",
        description: "Large clusters of flowers in various shades, broad toothed leaves.",
        highlight: "Long-lasting blooms, color-changing flowers (based on soil pH), good for drying.",
        care_tips: {
            light: "Morning sun and afternoon shade.",
            soil: "Well-draining soil.",
            water: "Regular watering to keep soil evenly moist.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune after flowering to control size and shape.",
                "Amend soil with aluminum sulfate for blue flowers or lime for pink flowers.",
                "Mulch around the base to retain moisture and protect roots."
            ]
        }
    },
    {
        id: 27,
        plant_name: "Japanese Maple (Acer palmatum)",
        //   image: JapaneseMapleImage, // Add your image reference here
        type: "outdoor",
        long_description: "Japanese Maples are small trees or large shrubs with delicate, palmate leaves that turn vibrant shades of red, orange, or yellow in autumn. They have a graceful, spreading growth habit.",
        best_for: "Ideal as a focal point, in containers, or in Japanese-style gardens.",
        description: "Small trees with delicate, palmate leaves and vibrant fall colors.",
        highlight: "Beautiful fall foliage, ornamental bark, slow-growing.",
        care_tips: {
            light: "Dappled shade.",
            soil: "Well-draining, slightly acidic soil.",
            water: "Regular watering to keep soil evenly moist.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Protect from hot afternoon sun and drying winds.",
                "Mulch around the base to retain moisture.",
                "Prune lightly to maintain shape and remove dead or damaged branches."
            ]
        }
    },
    {
        id: 28,
        plant_name: "Peony (Paeonia spp.)",
        //    image: PeonyImage, // Add your image reference here
        type: "outdoor",
        long_description: "Peonies are herbaceous perennial plants with large, fragrant flowers in shades of pink, white, or red. They have glossy, deep green foliage and a bushy growth habit.",
        best_for: "Perfect for borders, cutting gardens, or as a specimen plant.",
        description: "Large, fragrant flowers with glossy, deep green foliage.",
        highlight: "Long-lived blooms, fragrant flowers, low maintenance.",
        care_tips: {
            light: "Full sun to light shade.",
            soil: "Fertile, well-draining soil.",
            water: "Regular watering to keep soil evenly moist.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide support for heavy flower heads.",
                "Divide plants every few years in early autumn.",
                "Mulch around the base to retain moisture and protect roots."
            ]
        }
    },
    {
        id: 29,
        plant_name: "Daylily (Hemerocallis spp.)",
        //   image: DaylilyImage, // Add your image reference here
        type: "outdoor",
        long_description: "Daylilies are clump-forming perennial plants with strap-like leaves and trumpet-shaped flowers in various colors, including yellow, orange, pink, and red. Each flower blooms for one day, hence the name.",
        best_for: "Great for borders, mass planting, or naturalizing.",
        description: "Clump-forming perennials with strap-like leaves and trumpet-shaped flowers.",
        highlight: "Continuous blooming, low maintenance, adaptable to various soil conditions.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Regular watering to keep soil evenly moist.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Remove spent flower stalks to encourage reblooming.",
                "Divide clumps every few years to maintain vigor.",
                "Mulch around the base to retain moisture and suppress weeds."
            ]
        }
    },
    {
        id: 30,
        plant_name: "Clematis (Clematis spp.)",
        //  image: ClematisImage, // Add your image reference here
        type: "outdoor",
        long_description: "Clematis are climbing vines with woody stems and large, showy flowers in shades of blue, purple, pink, red, or white. They bloom from late spring to fall.",
        best_for: "Ideal for trellises, fences, or arbors.",
        description: "Climbing vines with woody stems and large, showy flowers.",
        highlight: "Versatile climbers, long-lasting blooms, attractive to pollinators.",
        care_tips: {
            light: "Base in shade, foliage and flowers in sun.",
            soil: "Fertile, well-draining soil.",
            water: "Regular watering to keep soil evenly moist.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide support for climbing stems.",
                "Prune to control size and promote flowering.",
                "Mulch around the base to retain moisture and protect roots."
            ]
        }
    }
    ,
    {
        id: 31,
        plant_name: "Anjir (Ficus carica)",
        //  image: AnjirImage, // Add your image reference here
        type: "outdoor",
        long_description: "Anjir, also known as Fig, is a deciduous tree with lobed leaves and sweet, pear-shaped fruits known as figs. Figs can be green, yellow, brown, or purple when ripe, depending on the variety.",
        best_for: "Great for orchards, edible landscapes, or as a standalone tree.",
        description: "Deciduous tree with lobed leaves and sweet, pear-shaped fruits.",
        highlight: "Delicious fruits, ornamental foliage, drought-tolerant once established.",
        care_tips: {
            light: "Full sun exposure.",
            soil: "Well-draining soil.",
            water: "Regular watering, especially during dry periods.",
            temperature: "Prefers warm climates.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune to shape and improve air circulation.",
                "Fertilize in early spring with balanced fertilizer.",
                "Mulch around the base to retain moisture."
            ]
        }
    },
    {
        id: 32,
        plant_name: "Avocado (Persea americana)",
        //   image: AvocadoImage, // Add your image reference here
        type: "outdoor",
        long_description: "Avocado is a medium to large evergreen tree with glossy, oval leaves and green-skinned, creamy-fleshed fruits known as avocados. They are rich in healthy fats and nutrients.",
        best_for: "Ideal for subtropical and tropical climates, orchards, or large gardens.",
        description: "Evergreen tree with glossy, oval leaves and nutrient-rich, creamy-fleshed fruits.",
        highlight: "Nutrient-rich fruits, attractive foliage, can be grown in containers.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Regular watering, especially during dry periods.",
            temperature: "Prefers warm climates.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide protection from strong winds and frost when young.",
                "Prune to shape and promote branching.",
                "Mulch around the base to retain moisture and suppress weeds."
            ]
        }
    },
    {
        id: 33,
        plant_name: "Heliconia (Heliconia spp.)",
        //   image: HeliconiaImage, // Add your image reference here
        type: "outdoor",
        long_description: "Heliconias are tropical plants with large, paddle-shaped leaves and brightly colored bracts that resemble flowers. They produce long-lasting inflorescences in shades of red, orange, yellow, or pink.",
        best_for: "Great for tropical gardens, borders, or as cut flowers.",
        description: "Tropical plants with large, paddle-shaped leaves and brightly colored bracts.",
        highlight: "Exotic blooms, attracts hummingbirds, long-lasting cut flowers.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Rich, well-draining soil with high organic content.",
            water: "Regular watering to keep soil evenly moist.",
            temperature: "Prefers warm, humid climates.",
            humidity: "High humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Mulch around plants to retain moisture.",
                "Fertilize with organic matter in spring.",
                "Protect from strong winds and frost."
            ]
        }
    },
    {
        id: 34,
        plant_name: "Bel (Aegle marmelos)",
        //   image: BelImage, // Add your image reference here
        type: "outdoor",
        long_description: "Bel, also known as Bael or Bengal Quince, is a deciduous tree with aromatic, trifoliate leaves and round, greenish-yellow fruits with sweet, aromatic pulp. It is revered in traditional medicine and cuisine.",
        best_for: "Ideal for edible landscapes, medicinal gardens, or as a shade tree.",
        description: "Deciduous tree with aromatic, trifoliate leaves and round, greenish-yellow fruits.",
        highlight: "Medicinal fruits, fragrant flowers, drought-tolerant once established.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Regular watering, especially during dry periods.",
            temperature: "Prefers warm climates.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune to shape and remove dead wood.",
                "Fertilize with organic matter in spring.",
                "Mulch around the base to retain moisture."
            ]
        }
    },
    {
        id: 35,
        plant_name: "Shampoo Palm (Trachycarpus fortunei)",
        //    image: ShampooPalmImage, // Add your image reference here
        type: "outdoor",
        long_description: "Shampoo Palm, also known as Windmill Palm, is a hardy palm tree with large, fan-shaped leaves and a slender trunk covered with persistent leaf bases. It's popular for its tolerance to cold climates.",
        best_for: "Great as a focal point, in containers, or in cold-hardy gardens.",
        description: "Hardy palm tree with large, fan-shaped leaves and a slender trunk.",
        highlight: "Cold-tolerant, architectural foliage, low maintenance.",
        care_tips: {
            light: "Partial shade to full sun.",
            soil: "Well-draining soil.",
            water: "Regular watering, especially when young.",
            temperature: "Tolerates a wide range of temperatures.",
            humidity: "Tolerates moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Protect from drying winds and frost in colder climates.",
                "Mulch around the base to retain moisture.",
                "Prune dead or damaged fronds to maintain appearance."
            ]
        }
    }
    ,

    {
        id: 36,
        plant_name: "Banana Tree (Musa spp.)",
        //    image: BananaTreeImage, // Add your image reference here
        type: "outdoor",
        long_description: "Banana Trees are large, herbaceous plants with broad, paddle-shaped leaves and pendulous clusters of edible fruits known as bananas. They are fast-growing and provide a tropical look to gardens.",
        best_for: "Ideal for tropical and subtropical gardens, edible landscapes, or as a container plant.",
        description: "Large, herbaceous plants with broad, paddle-shaped leaves and pendulous clusters of edible fruits.",
        highlight: "Edible fruits, fast growth, tropical appearance.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Fertile, well-draining soil.",
            water: "Regular watering, especially during dry periods.",
            temperature: "Prefers warm, tropical climates.",
            humidity: "High humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide regular watering and humidity.",
                "Mulch and fertilize regularly for optimal growth and fruit production.",
                "Protect from strong winds to prevent leaf damage."
            ]
        }
    }
    ,

    {
        id: 37,
        plant_name: "Mosambi (Citrus limetta)",
        //     image: MosambiImage, // Add your image reference here
        type: "outdoor",
        long_description: "Mosambi, also known as Sweet Lime, is a small, evergreen tree with glossy, ovate leaves and round, greenish-yellow fruits that are similar to limes but sweeter and less acidic.",
        best_for: "Great for orchards, home gardens, or as a container plant.",
        description: "Small, evergreen tree with glossy, ovate leaves and round, greenish-yellow fruits that are sweet and less acidic than limes.",
        highlight: "Juicy, sweet fruits, rich in vitamin C, refreshing juice.",
        care_tips: {
            light: "Full sun exposure.",
            soil: "Well-draining, fertile soil.",
            water: "Water regularly during dry periods.",
            temperature: "Prefers warm climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune to shape and improve air circulation.",
                "Fertilize in spring with a citrus-specific fertilizer.",
                "Protect from frost and strong winds."
            ]
        }
    }
    ,

    {
        id: 38,
        plant_name: "Awala (Emblica officinalis)",
        //    image: AwalaImage, // Add your image reference here
        type: "outdoor",
        long_description: "Awala, also known as Indian Gooseberry or Amla, is a medium-sized deciduous tree with feathery leaves and small, greenish-yellow fruits that are highly prized in Ayurvedic medicine for their health benefits.",
        best_for: "Ideal for medicinal gardens, orchards, or as a shade tree.",
        description: "Medium-sized deciduous tree with feathery leaves and small, greenish-yellow fruits rich in vitamin C and antioxidants.",
        highlight: "Highly nutritious fruits, rich in vitamin C and antioxidants.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Water regularly when young.",
            temperature: "Prefers warm climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune to shape and remove dead or diseased branches.",
                "Mulch around the base to retain moisture.",
                "Fertilize annually with organic compost."
            ]
        }
    },
    {
        id: 39,
        plant_name: "Limbu (Citrus limon)",
        //    image: LimbuImage, // Add your image reference here
        type: "outdoor",
        long_description: "Limbu, also known as Lemon, is a small evergreen tree with oval, serrated leaves and oval, yellow fruits known as lemons. They are known for their tart flavor and culinary versatility.",
        best_for: "Great for home gardens, containers, or as a decorative tree.",
        description: "Small evergreen tree with oval, serrated leaves and oval, yellow fruits known for their tart flavor and culinary versatility.",
        highlight: "Edible fruits, rich in vitamin C, refreshing juice.",
        care_tips: {
            light: "Full sun exposure.",
            soil: "Well-draining soil.",
            water: "Water regularly during dry spells.",
            temperature: "Prefers warm climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune to shape and improve air circulation.",
                "Fertilize with a citrus-specific fertilizer in spring and summer.",
                "Protect from frost and strong winds."
            ]
        }
    }
    ,

    {
        id: 36,
        plant_name: "Madhumalti (Combretum indicum)",
        //    image: MadhumaltiImage, // Add your image reference here
        type: "outdoor",
        long_description: "Madhumalti, also known as Rangoon Creeper, is a woody vine with glossy leaves and clusters of fragrant flowers that change color from white to pink and finally red as they age.",
        best_for: "Great for trellises, fences, or as a container plant.",
        description: "Woody vine with glossy leaves and clusters of fragrant flowers that change color from white to pink and finally red as they age.",
        highlight: "Fragrant flowers, color-changing blooms, attracts butterflies and bees.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Water regularly.",
            temperature: "Prefers warm climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide support for climbing stems.",
                "Prune after flowering to control size and promote new growth.",
                "Mulch around the base to retain moisture."
            ]
        }
    },
    {
        id: 37,
        plant_name: "Tagar (Valeriana wallichii)",
        //     image: TagarImage, // Add your image reference here
        type: "outdoor",
        long_description: "Tagar, also known as Indian Valerian, is a perennial herb with fragrant white or pink flowers clustered at the ends of long stems. It is known for its medicinal properties, particularly in traditional medicine.",
        best_for: "Ideal for herb gardens, medicinal gardens, or as a ground cover.",
        description: "Perennial herb with fragrant white or pink flowers clustered at the ends of long stems, known for its medicinal properties.",
        highlight: "Medicinal herb, fragrant flowers, attracts beneficial insects.",
        care_tips: {
            light: "Partial shade.",
            soil: "Well-draining soil.",
            water: "Keep soil evenly moist.",
            temperature: "Prefers moderate climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Harvest roots for medicinal use in autumn or spring.",
                "Fertilize with organic compost to encourage growth.",
                "Protect from extreme heat."
            ]
        }
    },
    {
        id: 38,
        plant_name: "Rudraksh (Elaeocarpus ganitrus)",
        //    image: RudrakshImage, // Add your image reference here
        type: "outdoor",
        long_description: "Rudraksha tree is a large evergreen tree with broad, elliptic leaves and blueberry-like fruits known as Rudraksha beads. These seeds are sacred in Hinduism and are used in traditional jewelry and for spiritual purposes.",
        best_for: "Ideal for sacred gardens, large landscapes, or as a shade tree.",
        description: "Large evergreen tree with broad, elliptic leaves and blueberry-like fruits known as Rudraksha beads, sacred in Hinduism.",
        highlight: "Sacred seeds, attractive foliage, cultural significance.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Water regularly when young.",
            temperature: "Prefers warm climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Protect from strong winds and frost in cooler climates.",
                "Mulch around the base to retain moisture.",
                "Prune to shape and remove dead wood."
            ]
        }
    }
    ,

    {
        id: 39,
        plant_name: "Royal Palm (Roystonea spp.)",
        //    image: RoyalPalmImage, // Add your image reference here
        type: "outdoor",
        long_description: "Royal Palms are majestic, tall palm trees with smooth, grey trunks and large, feather-shaped fronds at the top. They are iconic in tropical landscapes and symbolize luxury and grandeur.",
        best_for: "Ideal as a focal point, avenue planting, or in large gardens.",
        description: "Majestic, tall palm trees with smooth, grey trunks and large, feather-shaped fronds at the top.",
        highlight: "Tall and elegant stature, ornamental fronds, drought-tolerant.",
        care_tips: {
            light: "Full sun exposure.",
            soil: "Well-draining soil.",
            water: "Provide regular watering until established.",
            temperature: "Prefers warm climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune dead fronds to maintain cleanliness and prevent disease.",
                "Mulch around the base to retain moisture.",
                "Avoid over-watering to prevent root rot."
            ]
        }
    }
    ,
    {
        id: 40,
        plant_name: "Chafa (Michelia champaca)",
        //    image: ChafaImage, // Add your image reference here
        type: "outdoor",
        long_description: "Chafa, also known as Champaca or Joy Perfume Tree, is a medium-sized evergreen tree with glossy, leathery leaves and intensely fragrant, yellow to orange flowers. The flowers are used in perfumery and traditional ceremonies.",
        best_for: "Ideal for gardens, parks, or as a fragrant specimen tree.",
        description: "Medium-sized evergreen tree with glossy, leathery leaves and intensely fragrant, yellow to orange flowers.",
        highlight: "Fragrant flowers, ornamental foliage, cultural significance.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Regular watering; keep soil evenly moist.",
            temperature: "Prefers warm climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide occasional fertilization to promote healthy growth.",
                "Prune lightly after flowering to maintain shape and encourage new growth.",
                "Mulch around the base to retain moisture."
            ]
        }
    }
    ,
    {
        id: 41,
        plant_name: "Bakul (Mimusops elengi)",
        //    image: BakulImage, // Add your image reference here
        type: "outdoor",
        long_description: "Bakul is a medium-sized evergreen tree with glossy, leathery leaves and fragrant, star-shaped white flowers. The flowers are used in religious ceremonies and have a sweet, lingering fragrance.",
        best_for: "Great as a shade tree, street tree, or in gardens.",
        description: "Medium-sized evergreen tree with glossy, leathery leaves and fragrant, star-shaped white flowers.",
        highlight: "Fragrant flowers, ornamental foliage, cultural significance.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Regular watering; keep soil evenly moist.",
            temperature: "Prefers warm climates.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Water regularly when young to establish a strong root system.",
                "Prune to shape and promote flowering.",
                "Apply mulch around the base to retain moisture and suppress weeds."
            ]
        }
    }
    ,
    {
        id: 42,
        plant_name: "Tulsi (Ocimum tenuiflorum)",
        //   image: TulsiImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Tulsi, also known as Holy Basil, is a small, aromatic herb with highly aromatic leaves that are used in culinary and medicinal applications. It is revered in Hinduism and often grown in households for its spiritual significance.",
        best_for: "Ideal for herb gardens, medicinal gardens, or as a sacred plant.",
        description: "Small, aromatic herb with highly aromatic leaves used in culinary and medicinal applications.",
        highlight: "Medicinal herb, aromatic foliage, cultural significance.",
        care_tips: {
            light: "Full sun.",
            soil: "Well-draining soil.",
            water: "Regular watering; avoid waterlogging.",
            temperature: "Prefers warm temperatures.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Pinch off flower buds to encourage leaf growth.",
                "Harvest leaves as needed for culinary or medicinal use.",
                "Prune regularly to maintain compact growth."
            ]
        }
    }
    ,
    {
        id: 43,
        plant_name: "Jaswand (Hibiscus rosa-sinensis)",
        //   image: JaswandImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Jaswand, also known as Hibiscus or China Rose, is a flowering shrub with glossy leaves and large, showy flowers in shades of red, pink, white, or yellow. It is widely cultivated for its ornamental beauty.",
        best_for: "Great for hedges, borders, or as a specimen plant.",
        description: "Flowering shrub with glossy leaves and large, showy flowers in shades of red, pink, white, or yellow.",
        highlight: "Colorful flowers, attractive foliage, attracts pollinators.",
        care_tips: {
            light: "Full sun.",
            soil: "Well-draining soil.",
            water: "Regular watering during dry periods.",
            temperature: "Prefers warm temperatures.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune to shape and promote flowering.",
                "Apply a balanced fertilizer during the growing season.",
                "Mulch around the base to retain moisture."
            ]
        }
    }
    ,
    {
        id: 44,
        plant_name: "Madhumalati (Combretum indicum)",
        //   image: MadhumalatiImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Madhumalati, also known as Rangoon Creeper, is a woody vine with glossy leaves and clusters of fragrant flowers that change color from white to pink and finally red as they age.",
        best_for: "Great for trellises, fences, or as a container plant.",
        description: "Woody vine with glossy leaves and clusters of fragrant flowers.",
        highlight: "Fragrant flowers, color-changing blooms, attracts butterflies and bees.",
        care_tips: {
            light: "Full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Regular watering.",
            temperature: "Prefers warm temperatures.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide support for climbing stems.",
                "Prune after flowering to control size and promote new growth.",
                "Mulch around the base to retain moisture."
            ]
        }
    }
    ,
    {
        id: 45,
        plant_name: "Tulsi (Ocimum tenuiflorum)",
        //    image: TulsiImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Tulsi, also known as Holy Basil, is a small, aromatic herb with highly aromatic leaves that are used in culinary and medicinal applications. It is revered in Hinduism and often grown in households for its spiritual significance.",
        best_for: "Ideal for herb gardens, medicinal gardens, or as a sacred plant.",
        description: "Small, aromatic herb with highly aromatic leaves used in culinary and medicinal applications.",
        highlight: "Medicinal herb, aromatic foliage, cultural significance.",
        care_tips: {
            light: "Full sun.",
            soil: "Well-draining soil.",
            water: "Regular watering; avoid waterlogging.",
            temperature: "Prefers warm temperatures.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Pinch off flower buds to encourage leaf growth.",
                "Harvest leaves as needed for culinary or medicinal use.",
                "Prune regularly to maintain compact growth."
            ]
        }
    },

    {
        id: 46,
        plant_name: "Parijatak (Nyctanthes arbor-tristis)",
        //    image: ParijatakImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Parijatak, also known as Night-flowering Jasmine, is a small tree with fragrant, star-shaped white flowers that bloom at night and drop in the morning. It is considered sacred and has medicinal uses.",
        best_for: "Ideal for gardens, as a specimen plant, or for its medicinal value.",
        description: "Small tree with fragrant, star-shaped white flowers that bloom at night.",
        highlight: "Fragrant night-blooming flowers, medicinal properties, cultural significance.",
        care_tips: {
            light: "Full sun.",
            soil: "Well-draining soil.",
            water: "Regular watering when young.",
            temperature: "Prefers warm temperatures.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune to shape and promote flowering.",
                "Mulch around the base to retain moisture.",
                "Harvest flowers for medicinal use as needed."
            ]
        }
    }
    ,
    {
        id: 47,
        plant_name: "Jaswand (Hibiscus rosa-sinensis)",
        //    image: JaswandImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Jaswand, also known as Hibiscus or China Rose, is a flowering shrub with glossy leaves and large, showy flowers in shades of red, pink, white, or yellow. It is widely cultivated for its ornamental beauty.",
        best_for: "Great for hedges, borders, or as a specimen plant.",
        description: "Flowering shrub with glossy leaves and large, showy flowers.",
        highlight: "Colorful flowers, attractive foliage, attracts pollinators.",
        care_tips: {
            light: "Full sun.",
            soil: "Well-draining soil.",
            water: "Regular watering during dry periods.",
            temperature: "Prefers warm temperatures.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune to shape and promote flowering.",
                "Apply a balanced fertilizer during the growing season.",
                "Mulch around the base to retain moisture."
            ]
        }
    }
    ,
    {
        id: 48,
        plant_name: "Tagar (Valeriana wallichii)",
        //    image: TagarImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Tagar, also known as Indian Valerian, is a perennial herb with fragrant white or pink flowers clustered at the ends of long stems. It is known for its medicinal properties, particularly in traditional medicine.",
        best_for: "Ideal for herb gardens, medicinal gardens, or as a ground cover.",
        description: "Perennial herb with fragrant flowers clustered at the ends of long stems.",
        highlight: "Medicinal herb, fragrant flowers, attracts beneficial insects.",
        care_tips: {
            light: "Partial shade.",
            soil: "Well-draining soil.",
            water: "Keep soil evenly moist.",
            temperature: "Prefers moderate temperatures.",
            humidity: "Moderate humidity levels.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Harvest roots for medicinal use in autumn or spring.",
                "Divide plants every few years to maintain vigor.",
                "Apply organic mulch to retain moisture."
            ]
        }
    }
    ,
    {
        id: 49,
        plant_name: "Golden Areliya (Aralia or Polyscias fruticosa)",
        //     image: GoldenAreliyaImage, // Replace with your image reference
        type: "indoor",
        long_description: "Golden Areliya, also known as Ming Aralia, is a decorative shrub with finely divided, lacy foliage that ranges in color from deep green to golden yellow. It is an excellent indoor plant due to its attractive foliage and low maintenance requirements. It can grow up to 2 meters tall indoors and has a slow growth rate, making it ideal for small spaces.",
        best_for: "Ideal for indoor decor, office spaces, and shaded patios.",
        description: "Decorative shrub with finely divided, lacy foliage.",
        highlight: "Attractive, lacy foliage; air-purifying qualities; low maintenance.",
        care_tips: {
            light: "Prefers bright, indirect light but can tolerate low light conditions.",
            soil: "Well-draining potting mix.",
            water: "Water moderately, allowing the top inch of soil to dry out between waterings.",
            temperature: "Prefers temperatures between 18-24°C (65-75°F).",
            humidity: "Thrives in moderate to high humidity. Mist the leaves occasionally.",
            toxicity: "Non-toxic to humans and pets.",
            tips: [
                "Prune to maintain shape and encourage bushy growth.",
                "Rotate the plant occasionally to ensure even growth.",
                "Avoid placing near drafts or heating vents."
            ]
        }
    }
    ,
    {
        id: 50,
        plant_name: "Small Areca Palm (Dypsis lutescens)",
        //   image: ArecaPalmImage, // Replace with your image reference
        type: "indoor",
        long_description: "The Small Areca Palm, also known as Butterfly Palm, is a popular indoor palm with feathery, arching fronds. It is known for its ability to purify the air and its graceful, tropical appearance. This palm can reach a height of about 6-7 feet when grown indoors, making it suitable for small to medium-sized spaces.",
        best_for: "Indoor corners, living rooms, and offices.",
        description: "Popular indoor palm with feathery, arching fronds.",
        highlight: "Air-purifying; attractive, feathery fronds; low maintenance.",
        care_tips: {
            light: "Prefers bright, indirect light. Can tolerate some direct sunlight.",
            soil: "Well-draining, peat-based potting mix.",
            water: "Water when the top 2 inches of soil feel dry. Do not overwater.",
            temperature: "Thrives in temperatures between 18-24°C (65-75°F).",
            humidity: "Prefers high humidity. Mist the plant regularly.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Feed regularly during the growing season with a balanced fertilizer.",
                "Keep fronds clean by wiping with a damp cloth.",
                "Re-pot every 2-3 years to refresh the soil and provide room for growth."
            ]
        }
    }
    ,
    {
        id: 51,
        plant_name: "Asparagus Fern (Asparagus setaceus)",
        //     image: AsparagusFernImage, // Replace with your image reference
        type: "indoor",
        long_description: "Asparagus Fern, also known as Lace Fern, is a versatile and hardy plant with feathery, delicate foliage that resembles ferns. Despite its name, it is not a true fern but is related to the asparagus family. This plant can grow up to 2-3 feet in height and width, making it a great option for hanging baskets or ground cover.",
        best_for: "Hanging baskets, ground cover, or as a houseplant.",
        description: "Versatile plant with feathery, delicate foliage.",
        highlight: "Delicate, feathery foliage; easy to grow; suitable for various lighting conditions.",
        care_tips: {
            light: "Prefers bright, indirect light but can tolerate some shade.",
            soil: "Well-draining potting mix with organic matter.",
            water: "Keep the soil consistently moist but not waterlogged.",
            temperature: "Prefers temperatures between 18-24°C (65-75°F).",
            humidity: "Thrives in high humidity. Mist the plant regularly.",
            toxicity: "Mildly toxic to pets if ingested.",
            tips: [
                "Trim back yellow or brown foliage to maintain appearance.",
                "Fertilize lightly during the growing season to encourage growth.",
                "Repot every 1-2 years to refresh the soil and provide nutrients."
            ]
        }
    }
    ,
    {
        id: 52,
        plant_name: "Green Syngonium (Syngonium podophyllum)",
        //    image: SyngoniumImage, // Replace with your image reference
        type: "indoor",
        long_description: "Green Syngonium, also known as Arrowhead Vine, is a popular indoor plant with arrow-shaped leaves that can vary in color from green to variegated white or cream. It is known for its vigorous growth and ability to adapt to various light conditions. This plant can be grown as a compact bush or trained to climb on supports.",
        best_for: "Tabletops, hanging baskets, or as a climbing plant.",
        description: "Popular indoor plant with arrow-shaped leaves.",
        highlight: "Attractive, arrow-shaped leaves; easy to care for; versatile growth habits.",
        care_tips: {
            light: "Prefers bright, indirect light but can tolerate low light conditions.",
            soil: "Well-draining potting mix.",
            water: "Water when the top inch of soil feels dry. Avoid overwatering.",
            temperature: "Thrives in temperatures between 15-25°C (60-77°F).",
            humidity: "Prefers moderate to high humidity. Mist the leaves occasionally.",
            toxicity: "Toxic to pets if ingested.",
            tips: [
                "Prune regularly to maintain a bushy appearance.",
                "Provide support for climbing stems if training as a climber.",
                "Wipe leaves gently with a damp cloth to remove dust and maintain foliage health."
            ]
        }
    }
    ,
    {
        id: 53,
        plant_name: "Mini Ixora (Ixora spp.)",
        //    image: IxoraImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Mini Ixora, also known as Dwarf Ixora, is a compact shrub with clusters of small, tubular flowers in vibrant shades of red, orange, pink, or yellow. The plant has glossy, dark green leaves and blooms throughout the year, making it a popular choice for ornamental gardens and landscaping.",
        best_for: "Borders, containers, or as a flowering hedge.",
        description: "Compact shrub with vibrant flower clusters.",
        highlight: "Year-round blooms; vibrant flower clusters; compact growth habit.",
        care_tips: {
            light: "Prefers full sun to partial shade.",
            soil: "Well-draining, slightly acidic soil.",
            water: "Keep the soil consistently moist but not waterlogged.",
            temperature: "Thrives in temperatures between 18-30°C (65-86°F).",
            humidity: "Prefers high humidity. Mist the plant regularly.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune lightly after flowering to maintain shape and encourage new growth.",
                "Fertilize with a balanced fertilizer during the growing season to promote flowering.",
                "Inspect regularly for pests, especially aphids, and treat promptly if detected."
            ]
        }
    }
    ,
    {
        id: 54,
        plant_name: "Aparajita Plant (Clitoria ternatea)",
        //     image: AparajitaImage, // Replace with your image reference
        type: "outdoor",
        long_description: "The Aparajita Plant, also known as Butterfly Pea, is a climbing vine with striking blue flowers that resemble the shape of a butterfly. It is often grown for ornamental purposes and has medicinal properties. The flowers are used to make herbal tea and as a natural food coloring.",
        best_for: "Climbing on trellises, fences, or as a ground cover.",
        description: "Climbing vine with striking blue flowers.",
        highlight: "Vibrant blue flowers; medicinal properties; easy to grow.",
        care_tips: {
            light: "Prefers full sun to partial shade.",
            soil: "Well-draining soil.",
            water: "Water regularly to keep the soil moist.",
            temperature: "Thrives in temperatures between 20-30°C (68-86°F).",
            humidity: "Prefers moderate to high humidity.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Provide support for climbing stems with trellises or fences.",
                "Prune lightly after flowering to maintain shape and promote new growth.",
                "Harvest flowers for herbal tea or natural food coloring purposes."
            ]
        }
    }
    ,

    {
        id: 55,
        plant_name: "Butterfly Plant (Buddleja spp.)",
        //    image: ButterflyPlantImage, // Replace with your image reference
        type: "outdoor",
        long_description: "The Butterfly Plant, also known as Butterfly Bush, is a deciduous shrub with elongated clusters of small, tubular flowers that attract butterflies and other pollinators. The flowers come in a variety of colors, including purple, pink, white, and yellow.",
        best_for: "Butterfly gardens, borders, or as a specimen plant.",
        description: "Deciduous shrub with elongated clusters of small, tubular flowers.",
        highlight: "Attracts butterflies; vibrant flower clusters; easy to grow.",
        care_tips: {
            light: "Prefers full sun.",
            soil: "Well-draining soil.",
            water: "Water regularly during the growing season. Tolerates drought once established.",
            temperature: "Thrives in temperatures between 15-25°C (60-77°F).",
            humidity: "Prefers moderate humidity.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Prune in early spring to promote new growth and flowering.",
                "Deadhead spent flowers to encourage continuous blooming.",
                "Monitor for pests, such as aphids, and treat promptly if detected."
            ]
        }
    }
    ,

    {
        id: 56,
        plant_name: "Caladium (Caladium spp.)",
        //   image: CaladiumImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Caladium, also known as Elephant Ear or Angel Wings, is a tuberous plant known for its large, colorful, heart-shaped leaves. The leaves come in various shades of green, white, pink, and red, often with striking patterns and veining.",
        best_for: "Shaded gardens, containers, or as a houseplant.",
        description: "Tuberous plant with large, colorful, heart-shaped leaves.",
        highlight: "Colorful foliage; attractive leaf patterns; low maintenance.",
        care_tips: {
            light: "Prefers bright, indirect light or partial shade.",
            soil: "Well-draining, rich soil.",
            water: "Keep the soil consistently moist but not waterlogged.",
            temperature: "Thrives in temperatures between 20-30°C (68-86°F).",
            humidity: "Prefers high humidity. Mist the plant regularly.",
            toxicity: "Toxic to pets if ingested.",
            tips: [
                "Plant tubers with the eyes facing upward and cover lightly with soil.",
                "Mulch around plants to retain moisture and suppress weeds.",
                "Reduce watering during the plant's dormant period."
            ]
        }
    }
    ,

    {
        id: 57,
        plant_name: "Kalanchoe (Kalanchoe blossfeldiana)",
        //    image: KalanchoeImage, // Replace with your image reference
        type: "indoor",
        long_description: "Kalanchoe is a popular succulent plant known for its vibrant, long-lasting flowers and fleshy, green leaves. The flowers come in various colors, including red, pink, yellow, and white. This plant is easy to care for and can bloom for several weeks, making it a favorite among indoor gardeners.",
        best_for: "Indoor decor, windowsills, and tabletops.",
        description: "Succulent plant with vibrant, long-lasting flowers.",
        highlight: "Bright, long-lasting flowers; drought-tolerant; low maintenance.",
        care_tips: {
            light: "Prefers bright, indirect light. Can tolerate some direct sunlight.",
            soil: "Well-draining, cactus or succulent potting mix.",
            water: "Water thoroughly when the soil feels dry. Reduce watering during the winter.",
            temperature: "Thrives in temperatures between 15-23°C (59-73°F).",
            humidity: "Prefers low to moderate humidity.",
            toxicity: "Mildly toxic to pets if ingested.",
            tips: [
                "Allow the soil to dry out slightly between waterings to prevent root rot.",
                "Fertilize lightly during the growing season to promote flowering.",
                "Remove spent flowers to encourage new blooms."
            ]
        }
    }
    ,
    {
        id: 58,
        plant_name: "Pandanus (Pandanus spp.)",
        //    image: PandanusImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Pandanus, also known as Screw Pine, is a tropical plant with long, slender, spiky leaves that grow in a spiral pattern. It is often used as an ornamental plant in gardens and landscapes. The plant produces fragrant flowers and can grow up to several meters tall.",
        best_for: "Tropical landscapes, large containers, and as a specimen plant.",
        description: "Tropical plant with long, slender, spiky leaves.",
        highlight: "Unique spiral leaf pattern; fragrant flowers; tropical appearance.",
        care_tips: {
            light: "Prefers full sun to partial shade.",
            soil: "Well-draining, sandy soil.",
            water: "Water regularly to keep the soil moist but not waterlogged.",
            temperature: "Thrives in temperatures between 20-30°C (68-86°F).",
            humidity: "Prefers high humidity.",
            toxicity: "Non-toxic to humans and pets.",
            tips: [
                "Provide adequate space for the plant to spread out its leaves.",
                "Fertilize with a balanced fertilizer during the growing season.",
                "Protect from strong winds to prevent leaf damage."
            ]
        }
    }
    ,
    {
        id: 59,
        plant_name: "Dracaena (Dracaena spp.)",
        //    image: DracaenaImage, // Replace with your image reference
        type: "indoor",
        long_description: "Dracaena is a popular genus of houseplants known for their striking foliage and easy-care nature. These plants often have long, slender leaves that can be variegated with white, cream, or red stripes. Some common species include Dracaena marginata (Dragon Tree) and Dracaena fragrans (Corn Plant). Dracaenas are great for improving indoor air quality and adding a touch of greenery to any space.",
        best_for: "Indoor decor, office spaces, and shaded patios.",
        description: "Genus of houseplants with striking foliage.",
        highlight: "Striking foliage; air-purifying qualities; low maintenance.",
        care_tips: {
            light: "Prefers bright, indirect light but can tolerate low light conditions.",
            soil: "Well-draining potting mix.",
            water: "Water when the top inch of soil feels dry. Avoid overwatering.",
            temperature: "Thrives in temperatures between 18-24°C (65-75°F).",
            humidity: "Prefers moderate humidity. Mist the leaves occasionally.",
            toxicity: "Toxic to pets if ingested.",
            tips: [
                "Rotate the plant regularly to ensure even growth of leaves.",
                "Trim yellowing leaves to promote new growth.",
                "Wipe leaves with a damp cloth to remove dust."
            ]
        }
    }
    ,
    {
        id: 60,
        plant_name: "Areca Palm (Dypsis lutescens)",
        //    image: ArecaPalmImage, // Replace with your image reference
        type: "indoor",
        long_description: "Areca Palm, also known as Butterfly Palm, is a popular indoor palm with feathery, arching fronds. It is known for its ability to purify the air and its graceful, tropical appearance. This palm can reach a height of about 6-7 feet when grown indoors, making it suitable for small to medium-sized spaces.",
        best_for: "Indoor corners, living rooms, and offices.",
        description: "Indoor palm with feathery, arching fronds.",
        highlight: "Air-purifying; attractive, feathery fronds; low maintenance.",
        care_tips: {
            light: "Prefers bright, indirect light. Can tolerate some direct sunlight.",
            soil: "Well-draining, peat-based potting mix.",
            water: "Water when the top 2 inches of soil feel dry. Do not overwater.",
            temperature: "Thrives in temperatures between 18-24°C (65-75°F).",
            humidity: "Prefers high humidity. Mist the plant regularly.",
            toxicity: "Non-toxic to pets.",
            tips: [
                "Dust the fronds regularly to maintain their glossy appearance.",
                "Monitor for spider mites, especially in dry indoor conditions.",
                "Repot every 2-3 years to refresh the soil and provide more space."
            ]
        }
    }
    ,
    {
        id: 61,
        plant_name: "Lily (Lilium spp.)",
        //    image: LilyImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Lily is a genus of flowering plants that produce large, often fragrant flowers in a variety of colors including white, yellow, pink, red, and orange. Lilies are popular in gardens and as cut flowers for floral arrangements. They can grow from 2 to 6 feet tall depending on the species and variety.",
        best_for: "Gardens, flower beds, and as cut flowers.",
        description: "Genus of flowering plants with large, often fragrant flowers.",
        highlight: "Large, fragrant flowers; diverse colors; attractive in floral arrangements.",
        care_tips: {
            light: "Prefers full sun to partial shade.",
            soil: "Well-draining, slightly acidic to neutral soil.",
            water: "Water regularly to keep the soil evenly moist.",
            temperature: "Thrives in temperatures between 15-25°C (60-77°F).",
            humidity: "Prefers moderate humidity.",
            toxicity: "Toxic to pets, especially cats, if ingested.",
            tips: [
                "Mulch around plants to maintain soil moisture and suppress weeds.",
                "Divide bulbs every 3-4 years to rejuvenate the plant and increase blooms.",
                "Deadhead spent flowers to encourage continuous blooming."
            ]
        }
    }
    ,
    {
        id: 62,
        plant_name: "Green Cupressus (Cupressus spp.)",
        //    image: CupressusImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Cupressus, commonly known as Cypress, is a genus of evergreen coniferous trees and shrubs. They have needle-like or scale-like leaves and produce small, round cones. Cypress trees are often used in landscaping for hedges, windbreaks, or ornamental purposes. They can vary in height from small shrubs to large trees depending on the species.",
        best_for: "Hedges, windbreaks, and ornamental landscaping.",
        description: "Genus of evergreen coniferous trees and shrubs.",
        highlight: "Evergreen foliage; versatile in landscaping; drought-tolerant.",
        care_tips: {
            light: "Prefers full sun.",
            soil: "Well-draining soil, tolerant of a wide range of soil types.",
            water: "Water regularly until established, then reduce frequency.",
            temperature: "Thrives in temperatures between 10-30°C (50-86°F).",
            humidity: "Prefers moderate humidity.",
            toxicity: "Non-toxic to humans and pets.",
            tips: [
                "Prune in late winter to early spring to maintain shape and health.",
                "Monitor for cypress canker and treat promptly if detected.",
                "Apply a balanced fertilizer in early spring to promote growth."
            ]
        }
    }
    ,

    {
        id: 63,
        plant_name: "Chafa (Plumeria spp.)",
        //    image: ChafaImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Chafa, also known as Frangipani, is a tropical tree known for its beautiful, fragrant flowers that come in various colors such as white, yellow, pink, and red. The tree has a unique branching structure and thick, fleshy leaves. It is often grown for its flowers which are commonly used in leis and perfumes.",
        best_for: "Tropical gardens, container gardening, and ornamental landscaping.",
        description: "Tropical tree with beautiful, fragrant flowers.",
        highlight: "Fragrant, colorful flowers; unique branching structure; attractive foliage.",
        care_tips: {
            light: "Prefers full sun.",
            soil: "Well-draining, sandy or loamy soil.",
            water: "Water regularly during the growing season, reduce in winter.",
            temperature: "Thrives in temperatures between 18-30°C (65-86°F).",
            humidity: "Prefers moderate to high humidity.",
            toxicity: "Mildly toxic to pets if ingested.",
            tips: [
                "Prune after flowering to maintain shape and promote new growth.",
                "Apply a slow-release fertilizer in spring to support flowering.",
                "Protect from strong winds to prevent damage to branches."
            ]
        }
    }
    ,

    {
        id: 64,
        plant_name: "Asoka Gold (Saraca asoca)",
        //    image: AsokaGoldImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Asoka Gold, also known as the Ashoka Tree, is an evergreen tree native to the Indian subcontinent. It is revered in Indian culture and known for its beautiful, fragrant yellow-orange flowers that bloom in clusters. The tree has a spreading canopy and is often used in traditional medicine.",
        best_for: "Ornamental gardens, parks, and as a shade tree.",
        description: "Evergreen tree with beautiful, fragrant flowers.",
        highlight: "Fragrant, colorful flowers; cultural significance; medicinal properties.",
        care_tips: {
            light: "Prefers full sun to partial shade.",
            soil: "Well-draining, rich soil.",
            water: "Water regularly to keep the soil moist.",
            temperature: "Thrives in temperatures between 20-30°C (68-86°F).",
            humidity: "Prefers high humidity.",
            toxicity: "Non-toxic to humans and pets.",
            tips: [
                "Mulch around the base of the tree to retain moisture and suppress weeds.",
                "Prune dead or diseased branches in late winter to early spring.",
                "Fertilize with a balanced fertilizer in early spring and mid-summer."
            ]
        }
    }
    ,
    {
        id: 65,
        plant_name: "Sitafel (Annona squamosa, Sugar Apple)",
        //    image: SitafelImage, // Replace with your image reference
        type: "outdoor",
        long_description: "Sitafel, also known as Sugar Apple or Custard Apple, is a small tropical tree with a sweet, creamy fruit covered in a scaly skin. The tree has a spreading growth habit and can reach up to 15-20 feet in height. The fruit is highly valued for its delicious taste and nutritional benefits.",
        best_for: "Tropical and subtropical gardens, home orchards.",
        description: "Small tropical tree with sweet, creamy fruit.",
        highlight: "Sweet, creamy fruit; attractive foliage; easy to grow in suitable climates.",
        care_tips: {
            light: "Prefers full sun.",
            soil: "Well-draining, sandy or loamy soil.",
            water: "Water regularly, especially during the fruiting season.",
            temperature: "Thrives in temperatures between 20-30°C (68-86°F).",
            humidity: "Prefers high humidity.",
            toxicity: "Non-toxic to humans and pets.",
            tips: [
                "Prune to maintain a manageable height and shape.",
                "Protect young plants from strong winds until established.",
                "Apply a balanced fertilizer during the growing season."
            ]
        }
    }
];


export default OutDoor;