import React from 'react';
import './style.css';
import { useParams } from 'react-router-dom';
import IndoorData from '../../Assets/Indoor';
import OutdoorData from '../../Assets/OutDoor';
import { slugify } from '../../utils/slugify';

const PlantDetail = () => {
    const { type, slug } = useParams();
    console.log('Type:', type);
    console.log('Slug:', slug);
    let plant;

    if (type === 'indoor') {
        plant = IndoorData.find(item => {
            console.log('Slugify:', slugify(item.plant_name));
            return slugify(item.plant_name) === slug;
        });
    } else if (type === 'outdoor') {
        plant = OutdoorData.find(item => {
            // console.log('Slugify:', slugify(item.plant_name));
            return slugify(item.plant_name) === slug;
        });
    }

    if (!plant) {
        return <div className="error-message">Plant not found</div>;
    }

    const { plant_name, image, description, type: plantType, care_tips, long_description, best_for, highlight } = plant;

    return (
        <div className="container">
            <div className="card mb-3">
                <div className="row g-0">
                    <div className="col-md-6">
                        <img src={image} className="img-fluid rounded-start" alt={plant_name} />
                    </div>
                    <div className="col-md-6">
                        <div className="card-body">
                            <h3 className="card-title">{plant_name}</h3>
                            <p className="card-text">{description}</p>
                            <p className="card-text">{long_description}</p>
                            <p className='card-text'><strong>Type: </strong> {plantType}</p>
                            <p className="card-text"><strong>Best For:</strong> {best_for}</p>
                            <p className="card-text"><strong>Highlight:</strong> {highlight}</p>
                            <ul className="list-unstyled">
                                {Object.entries(care_tips).map(([key, value]) => (
                                    <li key={key} className='card-text'><strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlantDetail;
