import React, { useState } from 'react';
import Indoor from '../../Assets/Indoor';
import Card from '../Cards/Card';
import Outdoordata from '../../Assets/OutDoor';
import Plants from './Plants';


const HomeCom = () => {
  const [indoorVisibleCard, setIndoorVisibleCard] = useState(5);
  const [outdoorVisibleCard, setOutdoorVisibleCard] = useState(5);

  const handleOutdoorVisible = () => {
    setOutdoorVisibleCard((prev) => prev + 5);
  };

  const handleIndoorVisible = () => {
    setIndoorVisibleCard((prev) => prev + 5);
  };

  return (
    <div className="container">
      <h1 className="p-4">Indoor Plants</h1>
      <div className="row" style={{marginLeft: "20px"}}>
        {Indoor.slice(0, indoorVisibleCard).map((item) => (
          <div className="col-md-4" key={item.id}>
            <Card
              id={item.id}
              type="indoor"
              plant_name={item.plant_name}
              image={item.image}
              description={item.description}
            />
          </div>
        ))}
      </div>
      {indoorVisibleCard < Indoor.length && (
        <button className="btn btn-success mt-3" onClick={handleIndoorVisible}>Load More</button>
      )}
      <hr />
      <h1 className="p-4">Outdoor Plants</h1>
      <div className="row" style={{marginLeft: "20px"}}>
        {Outdoordata.slice(0, outdoorVisibleCard).map((item) => (
          <div className="col-md-4" key={item.id}>
            <Card
              id={item.id}
              type="outdoor"
              plant_name={item.plant_name}
              image={item.image}
              long_description={item.long_description}
              description={item.description}
              best_for={item.best_for}
              highlight={item.highlight}
            />
          </div>
        ))}
      </div>
      {outdoorVisibleCard < Outdoordata.length && (
        <button className="btn btn-success mt-3" onClick={handleOutdoorVisible}>Load More</button>
      )}
    </div>
  );
};

export default HomeCom;
