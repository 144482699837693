import React from 'react'
import { Link } from 'react-router-dom'
import { slugify } from '../../utils/slugify'


const CardDetails = (props) => {
  return (
    <div>
      <div className="container plant-card">
      <div className="row">
        <div className="col-md-6">
          <img src={props.image} alt={props.plant_name} className="img-fluid" style={{width : "650px", height: "450px"}} />
        </div>
        <div className="col-md-6">
          <h2>{props.plant_name}</h2>
          <p>{props.description}</p>
          <p> <strong>Type: </strong> {props.type} </p>

        <Link to={`/plant/${props.type}/${slugify(props.plant_name)}`} className='btn btn-success'>Learn More About {props.plant_name}</Link>
        </div>
      </div>
    </div>
    <br/>
    </div>
  )
}

export default CardDetails
