import React, { useState } from 'react'
import IndoorData from '../../Assets/Indoor'
import CardDetails from '../Cards/CardDetails'
import { InputGroup, Form } from 'react-bootstrap';

const IndoorCom = () => {
  const [indoorVisibleCard, setIndoorVisibleCard] = useState(4);
  const [search, setSearch] = useState('');

  const handleIndoorVisible = () => {
    setIndoorVisibleCard((prev) => prev + 4);
  };

  const filteredData = IndoorData.filter((item) => {
    return search.toLowerCase() === ''
      ? true
      : item.plant_name.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className='container'>
      <InputGroup className='my-3'>
        <Form.Control
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Plants"
        />
      </InputGroup>
      {filteredData.length > 0 ? (
        filteredData.slice(0, indoorVisibleCard).map((item) => (
          <CardDetails
            key={item.id}
            id={item.id}
            plant_name={item.plant_name}
            image={item.image}
            description={item.description}
            type={item.type}
          />
        ))
      ) : (
        <h4>Plant Name "{search}" is Not Available</h4>
      )}
      {filteredData.length > indoorVisibleCard && (
        <button className='btn btn-success' onClick={handleIndoorVisible}>Load More</button>
      )}
    </div>
  )
}

export default IndoorCom
