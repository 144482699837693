import ZZ from './media/zz-plant.8680d248f14e3aebdb59.png'
import Boston from './media/Boston-Fern-Nephrolepis-Exaltata.65bb753b6fec9fac25a1.png'
import Areca from './media/Areca-Palms-Dypsis-Lutescens.e40177d73d93bd618a33.png'
import Fiddle from './media/Fiddle-Leaf-Fig-Ficus-Lyrata.5719cb2ff27a66bafbf8.png'
import Pilea from './media/Pilea-Pilea-Peperomioides.44c73bd45c89132f0815.png'
import Bromeliads from './media/bromeliad-indoors.485e9a95d015909fb48e.png'
import Dragon from './media/dragontree.d64be480b84acb618a95.png'
import Pearls from './media/String-of-Pearls-Curio Rowleyanus.7b1440cfae03c3815b74.png'
import Croton from './media/Croton-Codiaeum-Variegatum.74888ec22e5ce62b1c29.png'
import Chinese from './media/aglaonema-chinese-evergreen-green.b6a5e8ad0a045bfdaa70.png'
import Ponytail from './media/Ponytail-Palm-Beaucarnea-Recurvata.3c8be0f3d87f353222e2.png'
import Oyster from './media/oyster-plant.c626f70b35b4975b24f0.png'
import Kalanchoe from './media/Kalanchoe-GettyImages.de4d2a14d71571df2073.png'
import Jasmine from './media/Jasmine-Plant-Jasminum.bafb593354332adabf43.png'
import Heartleaf from './media/Heartleaf-Philodendron-Philodendron-Hederaceum.1b63e447bc5f63684eb3.png'
import Begonia from './media/Begonia.6307c0410c0b98ffb6cf.png'
import Silver from './media/silver-leaf-philodendron.14aa50b067a6f0ad508c.png'
import Polka from './media/polka-dot-begonia.f86fac16fa5e7a44208b.png'
import Black from './media/black-velvet-alocasia.4a46569a4415b31545b5.png'
import Birds from './media/birds-nest-anthurium.c9d0ce143257f04ef3db.png'
import Ficus from './media/Ficus-Audrey-Ficus-benghalensis.eb86f2ffaa8958874ec3.png'
import Homalomena from './media/selby-plant.49dc99c8747c02125e34.png'
import English from './media/english-ivy-houseplant.559813b9f87f379da34a.png'
import Lucky from './media/Lucky-Bamboo-Dracaena-Sanderiana.419f042fb882a40784db.png'
import Money from './media/Money-Tree-Guiana-Chestnut.8a87612dda03e9665da6.png'
import Umbrella from './media/Umbrellatreeplant.fb41032ecacacb8d1f70.png'
import Rosemary from './media/rosemary.2849ffa7e3dcb52c475b.png'
import Monstera from './media/Monstera-Deliciosa.cbcd129a140aa1ee7897.png'
import Dumb from './media/Dumb-Cane-Plant-Dieffenbachia.adac5119f3074d05e50c.png'
import Rubber from './media/Rubber-plant-GettyImages.9f8fc346d2e5860d5232.png'
import Spider from './media/spider-plant.997fa3ffdd31ee98469f.png'
import Jade from './media/jade-plant.1762e5d3103b172ce621.png'
import African from './media/African-Violet-Saintpaulia-Ionantha.0d794bd779d0566cb410.png'
import Basil from './media/basil-Ocimum-Basilicum.bc6a1cf317b11706a705.png'
import Peace from './media/Peacelily-GettyImages.c3b57a692362b283b7a0.png'
import Moth from './media/phalaenopsis-cultivar--moth-orchid.090541035449c7278e86.png'
import snake from './media/snake-plant.66f90890ac4ef2828496.png'
import Golden from './media/golden-pothos.df918c6d2ccc55b3fa0c.png'
import Aloe from './media/aloe-vera-plant-in-white-flowerpot.069d4da4dcf9f6db3bf1.png'
import Sweetheart from './media/Heartleaf-Philodendron-Philodendron-Hederaceum.1b63e447bc5f63684eb3.png'
import Indiantree from './media/Indiantree_Spurge.jpeg'

const Indoor = [
  {
    id: 1,
    plant_name: "Aloe Vera",
    image: Aloe,
    type: "indoor",
    description: "This spiky, low-maintenance houseplant was the top-searched plant in nearly a dozen states, according to a recent survey by home furnishings site Joybird. It's no surprise, given its set-it-and-forget-it vibe (sunny window and minimal watering) and the beauty and health benefits you can reap from the gel inside the leaves.",
    best_for: "Indoor environments with bright light",
    highlight: "Produces soothing gel that is beneficial for skin care",
    toxicity: "Non-toxic to pets",
    care_tips: {
      light: "Bright light",
      soil: "Moist, well-draining soil",
      water: "Allow the plant to dry out between waterings.",
      temperature: "55 to 85 degrees Fahrenheit",
      humidity: "40%"
    },
    tips: "Aloe vera plants thrive in bright, indirect sunlight. They prefer well-draining soil, so ensure pots have drainage holes to prevent water from accumulating at the roots.",
    long_description: "Aloe vera is known for its spiky, succulent leaves that contain a clear gel often used topically for its soothing and healing properties. It is a popular indoor plant due to its low maintenance requirements and aesthetic appeal."
  },
  {
    id: 2,
    plant_name: "Golden Pothos (Epipremnum aureum)",
    image: Golden,
    type: "indoor",
    description: "Consistently on the list of best sellers on plant sites like Costa Farms and the Sill, the pothos (also called devil's vine and devil's ivy) scores major points with plant parents for being easy to care for and capable of thriving in even low light conditions.",
    best_for: "Indoor environments with low light",
    highlight: "Trails elegantly and purifies indoor air",
    toxicity: "Highly toxic to pets.",
    care_tips: {
      light: "Bright, indirect light (though can handle low light)",
      soil: "Moist, well-draining soil",
      water: "Let the soil dry out between waterings.",
      temperature: "65 to 75 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Golden pothos is a versatile plant that can tolerate low light conditions, making it ideal for offices and homes with less natural light. It benefits from occasional pruning to maintain its shape and encourage fuller growth.",
    long_description: "Golden pothos is a popular trailing houseplant known for its heart-shaped leaves and air-purifying qualities. It thrives in various light conditions and is relatively low maintenance, making it perfect for beginners."
  },
  {
    id: 3,
    plant_name: "Snake Plant (Dracaena trifasciata)",
    image: snake,
    type: "indoor",
    description: "This beginner-friendly plant (also known as mother-in-law's tongue, devil's tongue, or sansevieria) requires little care to thrive, and it's been a top-searched plant for the past several years.",
    best_for: "Indoor environments with low light and neglectful watering",
    highlight: "Air-purifying qualities and ability to thrive on neglect",
    toxicity: "Toxic to pets",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light well-draining soil, especially a mix made for succulents or cacti",
      water: "Let the soil dry out between waterings.",
      temperature: "70 to 90 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "Snake plants prefer to dry out between waterings and are tolerant of low light conditions. They are susceptible to root rot if overwatered, so it's essential to use pots with drainage holes and avoid excessive watering.",
    long_description: "Snake plants are known for their upright, sword-like leaves that can tolerate neglect and thrive in low light conditions. They are valued for their air-purifying abilities and are ideal for offices and homes."
  },
  {
    id: 4,
    plant_name: "Moth Orchid (Phalaenopsis amabilis)",
    image: Moth,
    type: "indoor",
    description: "Orchids are a fantastic plant choice for people who are looking to add a little color with a blooming houseplant.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Elegant blooms that can last for weeks",
    toxicity: "Non-toxic to pets",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light well-draining soil with bark, moss, or peat",
      water: "Let the soil dry out between waterings.",
      temperature: "50 to 90 degrees Fahrenheit",
      humidity: "40 to 70%"
    },
    tips: "Moth orchids thrive in humid environments and benefit from regular fertilization during their active growing season. They require well-draining media to prevent root rot and should be kept away from direct sunlight to avoid leaf burn.",
    long_description: "Moth orchids are known for their graceful arching sprays of blooms that come in various colors. They are popular indoor plants due to their elegant appearance and relatively low maintenance."
  },
  {
    id: 5,
    plant_name: "Peace Lily",
    image: Peace,
    type: "indoor",
    description: "This tropical plant has been a favorite among those who love flowers: It can bloom more than once per year, and the flowers last for weeks.",
    best_for: "Indoor environments with indirect light and high humidity",
    highlight: "Beautiful white blooms and air-purifying qualities",
    toxicity: "Toxic to pets.",
    care_tips: {
      light: "Indirect light",
      soil: "Moist well-draining soil",
      water: "Let the soil dry out between waterings.",
      temperature: "65 to 80 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Peace lilies thrive in high humidity environments and benefit from occasional misting. They are sensitive to chlorine and other chemicals in tap water, so using distilled or filtered water is recommended for watering.",
    long_description: "Peace lilies are known for their glossy green leaves and elegant white flowers that bloom periodically throughout the year. They are valued for their ability to purify indoor air and are suitable for homes and offices."
  },
  {
    id: 6,
    plant_name: "Basil (Ocimum Basilicum)",
    image: Basil,
    type: "indoor",
    description: "If you're growing herbs indoors, basil is likely at the top of your list. (Who doesn't love a little fresh basil to top their pasta or pizza?)",
    best_for: "Indoor environments with bright sunlight",
    highlight: "Fresh leaves perfect for culinary use",
    care_tips: {
      light: "Bright sunlight",
      soil: "Moist, well-draining soil",
      water: "Keep the soil moist and mist occasionally.",
      temperature: "70 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Basil thrives in warm, sunny conditions and benefits from regular harvesting to promote growth. Pinching off flowers helps maintain flavor and prolongs leaf production.",
    long_description: "Basil is a popular herb known for its aromatic leaves that are commonly used in culinary dishes, particularly Italian cuisine. It requires bright sunlight and consistently moist soil to thrive indoors."
  },
  {
    id: 7,
    plant_name: "African Violet (Saintpaulia Ionantha)",
    image: African,
    type: "indoor",
    description: "This pretty flowering houseplant has been a time-honored favorite, due to its sweet purple blooms that appear several times a year.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Sweet purple blooms throughout the year",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Moist well-draining soil",
      water: "Keep the soil moist.",
      temperature: "70 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "African violets prefer stable conditions with indirect light and moist soil. Watering from below or using a self-watering pot helps prevent leaf spots and maintains soil moisture.",
    long_description: "African violets are beloved for their delicate, purple flowers that bloom multiple times a year. They thrive in bright, indirect light and require consistent moisture to flourish indoors."
  },
  {
    id: 8,
    plant_name: "Jade Plant (Crassula Ovata)",
    image: Jade,
    type: "indoor",
    description: "Succulents have been a trending houseplant over the past few years, but the succulent jade plants have been a fan favorite for decades.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Succulent with fleshy, water-retaining leaves",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light well-draining soil (preferably a succulent-specific blend)",
      water: "Keep soil moist in spring and summer and water monthly in winter.",
      temperature: "60 to 75 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "Jade plants thrive in bright light and should be allowed to dry out between waterings to prevent root rot. They are drought-tolerant and benefit from occasional pruning to maintain shape.",
    long_description: "Jade plants are succulents known for their thick, fleshy leaves and tree-like appearance. They are easy to care for and thrive in bright, indirect light conditions typical of indoor environments."
  },
  {
    id: 9,
    plant_name: "Spider Plant (Chlorophytum Comosum)",
    image: Spider,
    type: "indoor",
    description: "Spider plants are kind of like the sourdough starter of plants. When they're thriving, they produce baby plants that you can easily snip and gift to a fellow plant parent.",
    best_for: "Indoor environments with bright to moderate indirect light",
    highlight: "Produces spider-like offshoots (babies) that can be propagated",
    care_tips: {
      light: "Bright to moderate indirect light",
      soil: "Loamy, well-draining soil",
      water: "Let the top 2 inches of soil dry out between waterings.",
      temperature: "50 to 90 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Spider plants are adaptable and grow well in a variety of conditions, but they prefer bright, indirect light. Regularly removing dead or yellow leaves and periodic repotting helps maintain plant health.",
    long_description: "Spider plants are characterized by their arching leaves and ability to produce spiderettes or plantlets that hang down from the mother plant. They are easy to care for and are popular for their air-purifying qualities."
  },

  {
    id: 10,
    plant_name: "Rubber Plant (Ficus Elastica)",
    image: Rubber,
    type: "indoor",
    description: "This tropical plant makes a great (if slightly high maintenance) houseplant, with its striking leaves and its ability to grow to tree status, making it a great plant to add to your home decor.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Striking, glossy leaves that add a tropical vibe to interiors",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light well-draining soil",
      water: "Keep the plant moist with regular watering.",
      temperature: "60 to 75 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "Rubber plants benefit from regular pruning to maintain their shape and size. Wiping the leaves with a damp cloth helps keep them free from dust and enhances their glossy appearance.",
    long_description: "The Rubber Plant, or Ficus Elastica, is known for its large, glossy leaves that add a tropical touch to indoor spaces. It thrives in bright, indirect light and requires consistent moisture to maintain its vibrant appearance."
  },
  {
    id: 11,
    plant_name: "Dumb Cane Plant (Dieffenbachia)",
    image: Dumb,
    type: "indoor",
    description: "Dumb cane plants are prized for their intriguing, patterned foliage, and their relatively easy-care nature. There are a ton of varieties to choose from, which can grow into larger trees that can be a centerpiece of your plant collection.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Patterned leaves in various shades that add interest to any indoor setting",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light well-draining soil",
      water: "Keep the soil moist with regular watering",
      temperature: "65 to 75 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Dumb cane plants are sensitive to cold drafts and overwatering. Ensure the soil is evenly moist and provide stable conditions to prevent leaf drop and maintain plant health.",
    toxicity: "Toxic to pets",
    long_description: "Dumb Cane plants, or Dieffenbachia, feature large, patterned leaves that come in various shades of green and white. They thrive in bright, indirect light and require consistent moisture to thrive indoors."
  },
  {
    id: 12,
    plant_name: "Monstera Deliciosa",
    image: Monstera,
    type: "indoor",
    description: "One of the stars of the past few years, the monstera (or Swiss cheese plant) is beloved for its pretty leaves. Monstera is one of my favorite plants that make a statement, perfect for a bright open space, Pangborn says.Known for its fast-growing nature and large leaves with dramatic holes that resemble Swiss cheese, the Monstera is an easy choice and is adaptable to most indoor environments.",
    best_for: "Bright, open indoor spaces",
    highlight: "Large, dramatic leaves with unique hole patterns",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Well-draining peat-based soil",
      water: "Keep the soil moist with regular watering during the spring and summer; water occasionally in the fall and winter",
      temperature: "65 to 75 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Monstera plants benefit from occasional misting to enhance humidity levels and prevent leaf browning. Regularly dusting the leaves helps maintain their glossy appearance and allows for better light absorption.",
    long_description: "The Monstera Deliciosa, also known as the Swiss cheese plant, is characterized by its large, perforated leaves that add a tropical flair to indoor spaces. It thrives in bright, indirect light and requires consistent moisture to support its fast growth."
  },
  {
    id: 13,
    plant_name: "Rosemary (Salvia Rosmarinus)",
    image: Rosemary,
    type: "indoor",
    description: "This perennial herb can be grown on a sunny windowsill, so you can add a touch of flavor to anything from cocktails to stews. It may be a little challenging to keep rosemary thriving during the winter months, as the plant needs lots of bright sunlight to do well. But it's a plant that tolerates drought, so err on the side of underwatering your rosemary.",
    best_for: "Indoor environments with bright full sunlight",
    highlight: "Aromatic herb with culinary uses",
    care_tips: {
      light: "Bright full sunlight",
      soil: "Dry well-draining soil (a cactus blend is ideal)",
      water: "Let the top of the soil dry out between waterings, with biweekly misting",
      temperature: "50 to 80 degrees Fahrenheit",
      humidity: "40 to 70%"
    },
    tips: "Rosemary thrives in sunny locations and requires well-draining soil to prevent root rot. Pruning encourages bushier growth and enhances the plant's aromatic oils, ideal for culinary use.",
    long_description: "Rosemary, or Salvia Rosmarinus, is a perennial herb known for its aromatic leaves that are popular in culinary dishes. It prefers bright full sunlight and well-draining soil, tolerating periods of drought better than excess moisture."
  },
  {
    id: 14,
    plant_name: "Umbrella Plant (Schefflera Actinophylla)",
    image: Umbrella,
    type: "indoor",
    description: "This pretty tropical plant can grow quickly, so be ready to repot. They favor the same conditions as other tropical plants—warm and humid.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Fast-growing tropical plant with umbrella-like leaves",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Rich, moist soil",
      water: "Let the soil dry between waterings before a good soak; misting is required during spring and summer",
      temperature: "60 to 80 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Umbrella plants benefit from regular pruning to manage growth and maintain shape. They thrive in humid environments and require consistent watering to prevent leaf drop.",
    toxicity: "Toxic to pets",
    long_description: "The Umbrella Plant, or Schefflera Actinophylla, is a fast-growing tropical plant known for its umbrella-like clusters of leaves. It thrives in bright, indirect light and requires regular watering to maintain soil moisture levels."
  },
  {
    id: 15,
    plant_name: "Money Tree (Guiana Chestnut)",
    image: Money,
    type: "indoor",
    description: "Money trees (AKA Guiana chestnut) have become a houseplant staple, due to their ability to thrive in a typical indoor environment. With large star-shaped leaves and a braided trunk, this special plant is known to bring good luck and fortune into your life, Pangborn says—and counts it among Bloomscape's top-selling plants. It's a fast-growing plant, which can quickly become a floor plant to spotlight in your room. It also thrives on humidity, so you'll want to mist (or keep a humidifier nearby), especially if your home is dry in the winter.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Symbol of good luck with braided trunk and large, distinctive leaves",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Rich, well-draining soil",
      water: "Water when the top inch of soil is dry, less frequently during the winter",
      temperature: "65 to 75 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Money trees benefit from regular pruning to maintain shape and encourage new growth. They are sensitive to overwatering, so allowing the soil to dry slightly between waterings helps prevent root rot and maintain plant health.",
    long_description: "The Money Tree, or Guiana Chestnut, is a popular indoor plant known for its braided trunk and large, star-shaped leaves. It symbolizes good luck and prosperity and thrives in bright, indirect light conditions."
  },


  {
    id: 16,
    plant_name: "Lucky Bamboo (Dracaena Sanderiana)",
    image: Lucky,
    type: "indoor",
    description: "Lucky bamboo are beloved for being a way to bring good luck into your home, and for being an especially easy to care for plant, since they grow in water—so you just need to ensure the roots are always in water. Fun fact: Lucky bamboo isn't actually bamboo. It's a succulent!",
    best_for: "Indoor environments with bright to moderate indirect light",
    highlight: "Symbol of good luck and easy-care water-growing plant",
    care_tips: {
      light: "Bright to moderate indirect light",
      soil: "Rich, well-drained potting soil or water",
      water: "Use bottled or distilled water, and change the water in the vase weekly",
      temperature: "65 to 90 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "Lucky Bamboo thrives when kept in clean, fresh water. Avoid using chlorinated water, as it can harm the plant. Regularly clean the vase and replace the water to prevent algae growth and ensure plant health.",
    long_description: "Lucky Bamboo, or Dracaena Sanderiana, is known for its association with bringing good luck and prosperity into the home. It grows well in water and prefers bright to moderate indirect light conditions."
  },
  {
    id: 17,
    plant_name: "English Ivy (Hedera helix)",
    image: English,
    type: "indoor",
    description: "Ivy's trailing behavior makes it a perfect choice for a hanging plant—and the plant will grow fast under indoor conditions. It can do well in low light conditions, so you can have it brighten up a darker corner.",
    best_for: "Indoor environments with moderate indirect light",
    highlight: "Trailing vine perfect for hanging baskets and low light areas",
    care_tips: {
      light: "Moderate indirect light",
      soil: "Moist, well-draining soil",
      water: "Let the top of the soil dry between waterings",
      temperature: "70 to 90 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "English Ivy thrives in humid conditions and benefits from occasional misting to increase humidity levels. Regularly trim the vines to maintain shape and encourage new growth.",
    long_description: "English Ivy, or Hedera helix, is a versatile indoor plant known for its trailing vines that thrive in moderate indirect light. It's ideal for hanging baskets and can tolerate lower light conditions."
  },
  {
    id: 18,
    plant_name: "ZZ Plant (Zamioculcas Zamiifolia)",
    image: ZZ,
    type: "indoor",
    description: "These popular plants feature gorgeous, deep green leaves, and they're hard to kill, making the ZZ plant an ideal pick for beginner plant parents. They can thrive in even low light conditions, making them a great choice for an office or a space in your home that doesn't get much light. And they also thrive on neglect, needing less water than other plants.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Low-maintenance plant with glossy, deep green leaves",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Well-draining soil",
      water: "Let the soil dry out completely between waterings",
      temperature: "50 to 90 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "ZZ Plants are drought tolerant and prefer to dry out between waterings. Avoid overwatering, as it can lead to root rot. Wipe the leaves with a damp cloth to keep them clean and enhance their glossy appearance.",
    long_description: "The ZZ Plant, or Zamioculcas Zamiifolia, is prized for its glossy, deep green leaves and resilience in low light conditions. It's a low-maintenance plant that thrives on neglect and is perfect for beginner plant enthusiasts."
  },
  {
    id: 19,
    plant_name: "Boston Fern (Nephrolepis Exaltata)",
    image: Boston,
    type: "indoor",
    description: "Boston ferns thrive on humidity and water, making them an ideal plant for your bathroom. (Even with all that shower humidity, you may need to mist them additionally to help them thrive.) It's also a plant that doesn't require much sunlight, so it's great for offices or darker rooms.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Lush, feathery fronds that thrive in high humidity",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Rich well-draining soil with peat and perlite",
      water: "Water frequently to keep the soil moist (but not soaked); reduce the watering schedule during the fall and winter",
      temperature: "65 to 75 degrees Fahrenheit",
      humidity: "Over 80%"
    },
    tips: "Boston Ferns benefit from high humidity levels and regular misting to keep their fronds lush and green. Avoid placing them near drafts or heating vents, as they prefer stable temperatures and humidity.",
    long_description: "Boston Fern, or Nephrolepis Exaltata, is a classic indoor plant known for its lush, feathery fronds that thrive in high humidity. It's ideal for bathrooms or spaces with indirect light and benefits from regular watering to maintain soil moisture."
  },
  {
    id: 20,
    plant_name: "Areca Palms (Dypsis Lutescens)",
    image: Areca,
    type: "indoor",
    description: "These big and beautiful palm trees (also known as the butterfly palm or the bamboo palm) make a statement in your room, growing up to 8 feet tall. The Areca palm thrives in indirect sunlight—but don't overwater this grand beauty.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Large, graceful palm with arching fronds",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Peaty, well-draining soil",
      water: "Let the soil dry out between waterings",
      temperature: "65 to 75 degrees Fahrenheit",
      humidity: "40 to 70%"
    },
    tips: "Areca Palms prefer slightly moist soil and benefit from occasional misting to maintain humidity levels. Prune yellow or brown fronds regularly to promote new growth and maintain plant health.",
    long_description: "Areca Palms, or Dypsis Lutescens, are large indoor plants known for their graceful, arching fronds that add a tropical touch to any space. They thrive in bright, indirect light and require moderate watering to prevent root rot."
  },
  {
    id: 21,
    plant_name: "Fiddle Leaf Fig (Ficus Lyrata)",
    image: Fiddle,
    type: "indoor",
    description: "This popular houseplant thrives on light, so you'll need to place it right in a southern or western window to avoid dropping leaves. (The fussiness has also caused the fiddle leaf fig to be placed on many difficult plants lists.) But if you can meet the light and water requirements, you'll have a gorgeous tree to showcase in your home.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Statement plant with large, violin-shaped leaves",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light well-draining soil",
      water: "Water when the top inch of soil is dry",
      temperature: "60 to 85 degrees Fahrenheit",
      humidity: "30 to 65%"
    },
    tips: "Fiddle Leaf Figs are sensitive to overwatering and prefer slightly dry conditions between waterings. Rotate the plant periodically to ensure even growth and wipe the leaves with a damp cloth to remove dust.",
    toxicity: "Toxic to pets",
    long_description: "Fiddle Leaf Fig, or Ficus Lyrata, is a striking indoor plant admired for its large, violin-shaped leaves. It thrives in bright, indirect light and requires careful watering to prevent root rot and maintain foliage health."
  },
  {
    id: 22,
    plant_name: "Pilea (Pilea Peperomioides)",
    image: Pilea,
    type: "indoor",
    description: "This popular houseplant's rounded leaves have earned it the nicknames Chinese money plant or pancake plant. It's an easy-to-care-for plant, making it a great choice for a beginner plant parent.",
    best_for: "Indoor environments with bright to moderate indirect light",
    highlight: "Rounded leaves and easy-care nature",
    care_tips: {
      light: "Bright to moderate indirect light",
      soil: "Rich well-draining soil",
      water: "Let the soil dry out between waterings",
      temperature: "50 to 90 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "Pilea plants propagate easily from offsets, making them a fun plant to share with friends. They prefer consistent temperatures and moderate humidity levels to thrive indoors.",
    long_description: "Pilea, or Pilea Peperomioides, is a charming indoor plant known for its round, coin-shaped leaves and easy-care nature. It thrives in bright to moderate indirect light and requires minimal maintenance, making it ideal for beginner plant enthusiasts."
  },


  {
    id: 23,
    plant_name: "Bromeliads",
    image: Bromeliads,
    type: "indoor",
    description: "Bromeliads are super popular, thanks to their colorful blooms and minimal needs. They're a type of air plant, so they can even grow without soil. It's a great choice for adding a splash of color to your indoor space.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Colorful blooms and minimal care needs",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Fast-draining soil or grown as an air plant",
      water: "Water into the center of the plant or by lightly moistening the soil",
      temperature: "60 to 85 degrees Fahrenheit",
      humidity: "40 to 50%"
    },
    tips: "Bromeliads benefit from occasional misting to increase humidity around the plant. Ensure water doesn't accumulate in the center rosette to prevent rotting.",
    long_description: "Bromeliads are known for their vibrant, long-lasting blooms and adaptability to various environments. They thrive in bright, indirect light and are often grown as air plants, making them a low-maintenance choice for indoor gardens."
  },
  {
    id: 24,
    plant_name: "Dragon Tree (Dracaena Marginata)",
    image: Dragon,
    type: "indoor",
    description: "This spiky plant is a popular pick for houseplant beginners, since it's pretty hard to kill. It also makes a great office plant due to its resilience and striking appearance.",
    best_for: "Indoor environments with bright to moderate indirect light",
    highlight: "Hardy, spiky foliage with air-purifying qualities",
    care_tips: {
      light: "Bright to moderate indirect light",
      soil: "Light well-draining soil",
      water: "Let soil dry out between waterings",
      temperature: "70 to 80 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "Dragon Trees are drought-tolerant and prefer to dry out between waterings. Wipe the leaves occasionally to remove dust and maintain their glossy appearance.",
    toxicity: "Toxic to pets",
    long_description: "Dragon Tree, or Dracaena Marginata, is characterized by its spiky foliage and air-purifying qualities, making it a popular choice for indoor environments. It thrives in bright to moderate indirect light and requires minimal watering."
  },
  {
    id: 25,
    plant_name: "String of Pearls (Curio Rowleyanus)",
    image: Pearls,
    type: "indoor",
    description: "This pretty trailing succulent is a perfect addition to a bookshelf or as a hanging plant. You can propagate it easily by snipping off the stems, making it a fun plant for propagation enthusiasts.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Trailing succulent with bead-like leaves",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Sandy, succulent, or cactus potting soil",
      water: "Water weekly or biweekly to keep lightly moist",
      temperature: "50 to 90 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "String of Pearls prefer slightly dry conditions between waterings and can be susceptible to root rot if overwatered. Propagate by planting cuttings in well-draining soil.",
    toxicity: "Toxic to pets and small children",
    long_description: "String of Pearls, or Curio Rowleyanus, is a charming trailing succulent known for its spherical, bead-like leaves. It thrives in bright, indirect light and requires minimal watering, making it an ideal choice for indoor hanging baskets or shelves."
  }
  ,

  {
    id: 26,
    plant_name: "Croton (Codiaeum Variegatum)",
    image: Croton,
    type: "indoor",
    description: "Croton's colorful foliage is a showstopper and a common reason it's a best-seller. It thrives in a warm, humid environment and benefits from a humidity tray or a humidifier to maintain its vibrant appearance.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Colorful foliage with various patterns and colors",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light well-draining soil",
      water: "Keep soil moist during summer and drier in winter",
      temperature: "60 to 80 degrees Fahrenheit",
      humidity: "40 to 80%"
    },
    tips: "Croton plants need consistent moisture, especially during the growing season. Watch for leaf drop if conditions fluctuate too much.",
    long_description: "Croton, or Codiaeum Variegatum, is prized for its stunning foliage with various patterns and colors. It thrives in bright, indirect light and requires high humidity to maintain its vibrant appearance."
  },
  {
    id: 27,
    plant_name: "Chinese Evergreen (Aglaonema Commutatum)",
    image: Chinese,
    type: "indoor",
    description: "Chinese Evergreens are popular for their easy-care nature and air-purifying qualities. They come in various leaf colors and thrive in low to moderate light conditions.",
    best_for: "Indoor environments with indirect or low light",
    highlight: "Easy-care plant with air-purifying qualities",
    care_tips: {
      light: "Indirect light or low light",
      soil: "Light well-draining soil",
      water: "Allow the soil to dry out between waterings",
      temperature: "70 to 90 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Chinese Evergreens are tolerant of low light conditions but maintain variegated varieties' coloration with brighter light. They benefit from occasional misting or a humid environment.",
    long_description: "Chinese Evergreen, or Aglaonema Commutatum, is known for its adaptability to various light and water conditions, making it an ideal choice for indoor environments. It's prized for its air-purifying qualities and comes in a variety of leaf colors."
  },
  {
    id: 28,
    plant_name: "Ponytail Palm (Beaucarnea Recurvata)",
    image: Ponytail,
    type: "indoor",
    description: "Ponytail Palms are beloved for their unique appearance with long, narrow leaves cascading from a thick, woody trunk. They're slow-growing and can tolerate indoor conditions well, needing only bright sunlight to thrive.",
    best_for: "Indoor environments with bright sunlight",
    highlight: "Drought-tolerant palm with a distinctive appearance",
    care_tips: {
      light: "Bright sunlight",
      soil: "Sandy, cactus, or succulent soil",
      water: "Water weekly to biweekly in spring and summer, and monthly in winter",
      temperature: "60 to 90 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "Ponytail Palms are drought-tolerant and prefer to dry out between waterings. They're well-suited for sunny spots and require minimal pruning.",
    long_description: "Ponytail Palm, or Beaucarnea Recurvata, is characterized by its unique appearance with cascading leaves and a thick trunk. It thrives in bright sunlight and is known for its resilience and low maintenance requirements."
  },
  {
    id: 29,
    plant_name: "Oyster Plant (Tradescantia Spathacea)",
    image: Oyster,
    type: "indoor",
    description: "Oyster Plants are prized for their colorful green-purple leaves and low maintenance requirements. They thrive in bright, indirect light and can tolerate occasional neglect.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Colorful foliage and easy-care nature",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Rich well-draining soil",
      water: "Allow the soil to dry out at least 2 inches between waterings",
      temperature: "50 to 80 degrees Fahrenheit",
      humidity: "40 to 70%"
    },
    tips: "Oyster Plants benefit from regular pruning to maintain their compact shape and encourage healthy growth. Avoid overwatering to prevent root rot.",
    long_description: "Oyster Plant, or Tradescantia Spathacea, is known for its attractive green-purple foliage and low maintenance requirements. It's ideal for indoor environments with bright, indirect light."
  },
  {
    id: 30,
    plant_name: "Kalanchoe",
    image: Kalanchoe,
    type: "indoor",
    description: "Kalanchoe is a popular succulent houseplant known for its vibrant blooms and easy-care nature. It thrives in bright, indirect light and is drought-tolerant, making it perfect for busy plant owners.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Colorful blooms and succulent foliage",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light well-draining soil, especially a succulent or cactus blend",
      water: "Allow the soil to dry out 1 to 2 inches between waterings",
      temperature: "60 to 80 degrees Fahrenheit",
      humidity: "30 to 50%"
    },
    tips: "Kalanchoe plants are sensitive to overwatering, so ensure good drainage in the pot. Remove spent flowers to encourage new blooms.",
    long_description: "Kalanchoe is prized for its colorful blooms and succulent foliage, making it a popular choice for indoor environments. It requires bright, indirect light and minimal watering, making it suitable for beginners."
  },


  {
    id: 31,
    plant_name: "Jasmine Plant (Jasminum)",
    image: Jasmine,
    type: "indoor",
    description: "Jasmine plants are prized for their beautiful, fragrant flowers and can be grown as vines or hanging plants indoors. They require steady, moist soil and bright, indirect light to thrive.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Fragrant flowers and vine-like growth",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Well-draining soil with bark, perlite, or peat",
      water: "Allow the top half inch of soil to dry out between waterings",
      temperature: "60 to 75 degrees Fahrenheit",
      humidity: "40 to 60%"
    },
    tips: "Jasmine plants benefit from occasional pruning to maintain shape and encourage new growth. They may bloom profusely in spring and summer with proper care.",
    long_description: "Jasmine, known for its fragrant flowers and vine-like growth, is an excellent choice for indoor gardening. It requires bright, indirect light and consistent moisture to flourish."
  },
  {
    id: 32,
    plant_name: "Heartleaf Philodendron (Philodendron Hederaceum)",
    image: Heartleaf,
    type: "indoor",
    description: "The Heartleaf Philodendron, also known as the sweetheart plant, features heart-shaped leaves and is incredibly easy to grow. It thrives in low light conditions and is tolerant of neglect, making it ideal for beginners.",
    best_for: "Indoor environments with low to moderate light",
    highlight: "Heart-shaped leaves and low-maintenance care",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Soilless potting mix with coconut coir, peat, and pine bark",
      water: "Allow the soil to dry out 1 to 2 inches between waterings",
      temperature: "65 to 75 degrees Fahrenheit",
      humidity: "40 to 50%",
      pet_toxicity: "Toxic to pets and small kids"
    },
    tips: "Heartleaf Philodendrons benefit from occasional pruning to control size and maintain health. They can trail or climb with support, adding versatility to indoor spaces.",
    long_description: "Heartleaf Philodendron, or Philodendron Hederaceum, is loved for its heart-shaped leaves and easy-care nature. It thrives in low light conditions and is perfect for those new to indoor gardening."
  },
  {
    id: 33,
    plant_name: "Begonia",
    image: Begonia,
    type: "indoor",
    description: "Begonias are prized for their colorful foliage and ease of care. They require bright, indirect light and moderate watering, making them a versatile choice for indoor environments.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Colorful foliage and low-maintenance care",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Rich, light potting soil with peat",
      water: "Allow the soil to dry out 1 inch between waterings, water less in winter",
      temperature: "60 to 75 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Begonias benefit from regular fertilization during the growing season to encourage vibrant foliage. Avoid overwatering to prevent root rot.",
    long_description: "Begonias are known for their colorful foliage and easy-care requirements. They thrive in bright, indirect light and moderate humidity, making them suitable for various indoor settings."
  },
  {
    id: 34,
    plant_name: "Silver Leaf Philodendron (Philodendron Brandtianum)",
    image: Silver,
    type: "indoor",
    description: "The Silver Leaf Philodendron, also known as Philodendron Brandtianum or Brandi Philo, features silver-veined, heart-shaped leaves. It's a vining plant that thrives in bright, indirect light and adapts well to different indoor conditions.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Silver-veined leaves and adaptable growth",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Well-draining soil",
      water: "Water regularly during the growing season; keep soil moist",
      temperature: "65 to 85 degrees Fahrenheit",
      humidity: "50 to 60%"
    },
    tips: "Silver Leaf Philodendrons can be trained to climb or cascade, adding decorative appeal to indoor spaces. They benefit from occasional misting to increase humidity.",
    long_description: "Silver Leaf Philodendron, or Philodendron Brandtianum, is prized for its silver-veined leaves and adaptable growth habits. It thrives in bright, indirect light and is suitable for both novice and experienced indoor gardeners."
  },
  {
    id: 35,
    plant_name: "Polka Dot Begonia (Begonia Maculata)",
    image: Polka,
    type: "indoor",
    description: "Polka Dot Begonia, also known as Begonia Maculata, features cane-like stems with emerald green leaves adorned with silver spots. It thrives in bright, indirect light and rewards with clusters of white blooms.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Silver-spotted leaves and white flower clusters",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light potting soil or sandy loam",
      water: "Allow the soil to dry completely between waterings; avoid misting",
      temperature: "70 to 80 degrees Fahrenheit",
      humidity: "20 to 50%"
    },
    tips: "Polka Dot Begonias are high-maintenance plants that thrive in high humidity. They benefit from occasional pruning and are a striking addition to any indoor plant collection.",
    long_description: "Polka Dot Begonia, or Begonia Maculata, is prized for its cane-like stems and striking silver-spotted leaves. It requires bright, indirect light and high humidity to flourish."
  }
  ,

  {
    id: 36,
    plant_name: "Black Velvet Alocasia (Alocasia Reginula)",
    image: Black,
    type: "indoor",
    description: "The Black Velvet Alocasia, or Alocasia Reginula, is a striking houseplant known for its unique, velvety leaves with white veining. It thrives in bright, indirect light and requires well-draining soil to prevent root rot.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Velvety leaves with striking white veining",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light, well-draining potting soil",
      water: "Allow the top inch of soil to dry out between waterings",
      temperature: "55 to 85 degrees Fahrenheit",
      humidity: "40 to 60%"
    },
    tips: "Black Velvet Alocasia benefits from occasional misting to increase humidity around the plant. Avoid placing it in direct sunlight to prevent leaf burn.",
    long_description: "Black Velvet Alocasia, or Alocasia Reginula, is prized for its velvety leaves and striking white veining. It requires bright, indirect light and moderate humidity to thrive indoors."
  },
  {
    id: 37,
    plant_name: "Bird's Nest Anthurium (Anthurium Superbum)",
    image: Birds,
    type: "indoor",
    description: "The Bird's Nest Anthurium, or Anthurium Superbum, features waffled, shiny leaves and is an excellent choice for indoor gardening enthusiasts. It requires bright, indirect light and consistent moisture to flourish.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Waffled, shiny leaves and unique structural roots",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light, well-draining potting soil",
      water: "Keep the soil moist but not soaked; mist the leaves often",
      temperature: "70 to 90 degrees Fahrenheit",
      humidity: "65 to 80%"
    },
    tips: "Bird's Nest Anthurium benefits from regular misting to increase humidity levels. It grows slowly and requires patience but rewards with its unique foliage.",
    long_description: "Bird's Nest Anthurium, or Anthurium Superbum, is prized for its waffled, shiny leaves and distinctive structural roots. It thrives in bright, indirect light and high humidity."
  },
  {
    id: 38,
    plant_name: "Ficus Audrey (Ficus benghalensis)",
    image: Ficus,
    type: "indoor",
    description: "The Ficus Audrey, or Ficus benghalensis, features deep green leaves with dramatic yellow veins. It's a low-maintenance plant that thrives in bright, indirect light and adapts well to indoor conditions.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Deep green leaves with dramatic yellow veins",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light, well-draining soil, especially a mix for African violets",
      water: "Water the soil when the top inch feels dry",
      temperature: "65 to 75 degrees Fahrenheit",
      humidity: "40 to 60%"
    },
    tips: "Ficus Audrey benefits from occasional pruning to maintain shape and encourage new growth. It's more tolerant compared to other ficus varieties.",
    long_description: "Ficus Audrey, or Ficus benghalensis, is admired for its deep green leaves and dramatic yellow veins. It thrives in bright, indirect light and is ideal for indoor environments."
  },
  {
    id: 39,
    plant_name: "Homalomena Selby (Homalomena hybrid)",
    image: Homalomena,
    type: "indoor",
    description: "Homalomena Selby, a hybrid variety, boasts rich yellow and green foliage with ornate markings. It's a compact grower that thrives in bright, indirect light and requires consistent moisture to flourish indoors.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Rich yellow and green foliage with ornate markings",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Rich, well-draining soil",
      water: "Keep the soil consistently moist but not soggy",
      temperature: "60 to 90 degrees Fahrenheit",
      humidity: "50 to 70%"
    },
    tips: "Homalomena Selby benefits from regular fertilization during the growing season to maintain vibrant foliage. It's native to Southeastern Asia and thrives in humid conditions.",
    long_description: "Homalomena Selby, a hybrid plant, showcases rich yellow and green foliage with ornate markings. It requires bright, indirect light and moderate humidity to thrive indoors."
  },
  {
    id: 40,
    plant_name: "Sweetheart Plant (Hoya Kerrii)",
    image: Sweetheart,
    type: "indoor",
    description: "The Sweetheart Plant, or Hoya Kerrii, is a vining succulent native to Thailand. It features heart-shaped leaves and is a low-maintenance option that thrives in bright, indirect light.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Heart-shaped leaves and low-maintenance care",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Light, well-draining potting soil",
      water: "Water regularly, allowing the top inch of soil to dry out between waterings",
      temperature: "60 to 75 degrees Fahrenheit",
      humidity: "50 to 60%"
    },
    tips: "Sweetheart Plant is slow-growing and susceptible to overwatering. It's best suited for environments with good air circulation.",
    long_description: "Sweetheart Plant, or Hoya Kerrii, is cherished for its heart-shaped leaves and ease of care. It requires bright, indirect light and infrequent watering, making it an ideal addition to indoor gardens."
  }
  ,


  {
    id: 41,
    plant_name: "Indiantree Spurge",
    image: Indiantree,
    type: "indoor",
    description: "Indiantree Spurge (Euphorbia tirucalli L.) is a succulent shrub in the Euphorbiaceae family. It has pencil-like, green stems and produces small, yellow flowers. This plant is drought-tolerant and can be grown indoors in containers.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Pencil-like green stems and drought-tolerant nature",
    care_tips: {
      light: "Bright, indirect light",
      soil: "Well-draining, sandy soil",
      water: "Water sparingly; allow the soil to dry out between waterings",
      temperature: "Prefers temperatures between 65-75°F (18-24°C)",
      humidity: "Tolerant of low humidity",
      tip: "Handle with care, as the plant's sap can be irritating to the skin."
    },
    long_description: "Indiantree Spurge, or Euphorbia tirucalli L., is known for its pencil-like green stems and drought-tolerant nature. It thrives in bright, indirect light and is suitable for indoor environments."
  },
  {
    id: 42,
    plant_name: "White Aglaonema",
    // image: White_Aglaonema,
    type: "indoor",
    description: "White Aglaonema, also known as Chinese Evergreen, is a striking houseplant with lush, elongated leaves featuring shades of white and green. The leaves are broad and lance-shaped with intricate patterns and speckles of white, making it a visually appealing addition to any indoor space. White Aglaonema is known for its low-maintenance nature and its ability to thrive in low-light conditions, making it perfect for offices and homes.",
    best_for: "Indoor environments with medium to low indirect light",
    highlight: "Lush, elongated leaves with white and green patterns",
    care_tips: {
      light: "Medium to low indirect light. Avoid direct sunlight as it can scorch the leaves",
      soil: "Well-draining potting mix, rich in organic matter",
      water: "Allow the soil to dry out slightly between waterings. Overwatering can lead to root rot",
      temperature: "Prefers temperatures between 60-75°F (15-24°C). Avoid cold drafts and sudden temperature changes",
      humidity: "Enjoys high humidity but can tolerate average household humidity levels",
      tip: "Regularly wipe the leaves to remove dust and promote photosynthesis. Rotate the plant occasionally to ensure even growth."
    },
    long_description: "White Aglaonema, or Chinese Evergreen, features lush, elongated leaves with white and green patterns. It thrives in medium to low indirect light and is celebrated for its low-maintenance nature."
  },
  {
    id: 43,
    plant_name: "Lipstick Aglaonema",
    // image: Lipstick_Aglaonema,
    type: "indoor",
    description: "Lipstick Aglaonema is a vibrant variety of Aglaonema, known for its bold, red-edged leaves that resemble the color of lipstick. The leaves are broad, glossy, and adorned with striking green patterns interspersed with red margins. This plant adds a pop of color to indoor spaces and is relatively easy to care for, making it a popular choice among plant enthusiasts.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Bold, red-edged leaves with glossy texture",
    care_tips: {
      light: "Bright, indirect light. It can tolerate low light but will have more vibrant colors in brighter conditions",
      soil: "Well-draining potting mix with good aeration",
      water: "Water moderately, allowing the top inch of soil to dry out between waterings",
      temperature: "Thrives in temperatures between 65-80°F (18-27°C)",
      humidity: "Prefers high humidity but can adapt to lower levels",
      toxicity: "Toxic to pets and humans if ingested",
      tip: "Mist the leaves regularly to increase humidity and promote healthy growth. Avoid cold drafts and sudden temperature changes."
    },
    long_description: "Lipstick Aglaonema is prized for its bold, red-edged leaves and glossy texture. It thrives in bright, indirect light and adds vibrant color to indoor spaces."
  },
  {
    id: 44,
    plant_name: "Pink Aglaonema",
    // image: Pink_Aglaonema,
    type: "indoor",
    description: "Pink Aglaonema is a stunning indoor plant with variegated leaves featuring shades of pink, green, and white. The pink hues in the leaves make it a visually captivating plant that can brighten up any indoor space. It is known for its easy-going nature and adaptability to various light conditions, making it an excellent choice for beginners.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Variegated leaves with shades of pink, green, and white",
    care_tips: {
      light: "Prefers bright, indirect light but can tolerate low light",
      soil: "Use a well-draining potting mix",
      water: "Keep the soil evenly moist but not waterlogged. Allow the top inch to dry out between waterings",
      temperature: "Best kept in temperatures between 60-75°F (15-24°C)",
      humidity: "Enjoys higher humidity levels but can adapt to average indoor humidity",
      toxicity: "Toxic if ingested by pets or humans",
      tip: "Regularly clean the leaves to remove dust and ensure optimal photosynthesis. Rotate the plant to ensure even growth."
    },
    long_description: "Pink Aglaonema features variegated leaves with shades of pink, green, and white. It thrives in bright, indirect light and is prized for its easy-going nature."
  },
  {
    id: 45,
    plant_name: "Valentin Aglaonema",
    // image: Valentin_Aglaonema,
    type: "indoor",
    description: "Valentin Aglaonema is another variety of Aglaonema with unique, heart-shaped leaves. The leaves are a mix of green and pink, with pinkish-red central veins that add a touch of elegance and charm to the plant. Valentin Aglaonema is appreciated for its decorative foliage and its ability to thrive in low-light environments.",
    best_for: "Indoor environments with low to bright indirect light",
    highlight: "Heart-shaped leaves with green and pink hues",
    care_tips: {
      light: "Low to bright indirect light",
      soil: "Well-draining potting soil",
      water: "Water when the top inch of soil feels dry",
      temperature: "Optimal temperature range is 65-80°F (18-27°C)",
      humidity: "Prefers higher humidity but is tolerant of average indoor levels",
      toxicity: "Toxic to pets and humans if ingested",
      tip: "Rotate the plant occasionally for uniform growth. Clean the leaves regularly to keep them dust-free."
    },
    long_description: "Valentin Aglaonema features heart-shaped leaves with green and pink hues. It thrives in low to bright indirect light and adds elegance to indoor spaces."
  },

  {
    id: 46,
    plant_name: "Dieffenbachia",
    // image: Dieffenbachia,
    type: "indoor",
    description: "Dieffenbachia, also known as Dumb Cane, is a popular houseplant with large, oblong leaves adorned with variegated patterns in green, white, and yellow. The plant gets its common name from the toxic sap, which can cause temporary speech loss if ingested. Dieffenbachia is prized for its lush, tropical appearance and is relatively easy to care for.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Large, oblong leaves with variegated patterns",
    care_tips: {
      light: "Bright, indirect light. It can tolerate low light but may grow slower.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil consistently moist but not soggy. Allow the top inch to dry out between waterings.",
      temperature: "Prefers temperatures between 60-75°F (15-24°C).",
      humidity: "Thrives in high humidity.",
      toxicity: "Highly toxic if ingested, causing oral irritation and swelling.",
      tip: "Wipe leaves regularly to remove dust. Avoid placing the plant in cold drafts or near heating vents."
    },
    long_description: "Dieffenbachia, or Dumb Cane, features large, variegated leaves in green, white, and yellow. It thrives in bright, indirect light and is admired for its lush, tropical appearance."
  },
  {
    id: 47,
    plant_name: "Chamaedorea",
    // image: Chamaedorea,
    type: "indoor",
    description: "Chamaedorea, commonly known as the Parlor Palm, is a graceful and elegant houseplant with feathery, arching fronds. It is a compact and slow-growing palm that can adapt to low light conditions, making it an ideal choice for indoor settings. The Parlor Palm adds a touch of tropical flair to any room and is relatively low-maintenance.",
    best_for: "Indoor environments with low to medium indirect light",
    highlight: "Graceful, arching fronds with a tropical appearance",
    care_tips: {
      light: "Low to medium indirect light. Avoid direct sunlight.",
      soil: "Well-draining potting mix.",
      water: "Water when the top inch of soil feels dry.",
      temperature: "Best kept in temperatures between 65-80°F (18-27°C).",
      humidity: "Prefers higher humidity but can tolerate average indoor levels.",
      toxicity: "Non-toxic to pets and humans.",
      tip: "Mist the fronds regularly to increase humidity. Keep the plant away from cold drafts and sudden temperature changes."
    },
    long_description: "Chamaedorea, or Parlor Palm, features graceful, arching fronds and thrives in low to medium indirect light. It adds a tropical touch to indoor spaces."
  },
  {
    id: 48,
    plant_name: "Janadoh",
    // image: Janadoh,
    type: "indoor",
    description: "Janadoh is a lesser-known indoor plant with attractive, lance-shaped leaves. The foliage is typically green with unique variegation patterns that add an exotic touch to indoor gardens. This plant is appreciated for its striking appearance and ease of care, making it suitable for both beginners and experienced plant enthusiasts.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Lance-shaped leaves with unique variegation patterns",
    care_tips: {
      light: "Bright, indirect light.",
      soil: "Well-draining potting mix.",
      water: "Water moderately, allowing the soil to dry slightly between waterings.",
      temperature: "Thrives in temperatures between 60-75°F (15-24°C).",
      humidity: "Prefers higher humidity levels.",
      toxicity: "Generally non-toxic, but it's best to keep out of reach of pets and children.",
      tip: "Rotate the plant regularly to promote even growth. Keep the leaves clean and free from dust."
    },
    long_description: "Janadoh features lance-shaped leaves with unique variegation patterns. It thrives in bright, indirect light and is admired for its striking appearance."
  },
  {
    id: 49,
    plant_name: "Pink Syngonium",
    // image: Pink_Syngonium,
    type: "indoor",
    description: "Pink Syngonium, also known as Arrowhead Plant, is a beautiful houseplant with arrow-shaped leaves that display shades of pink, cream, and green. The plant's trailing habit makes it an excellent choice for hanging baskets or as a climbing plant. Pink Syngonium is easy to care for and adapts well to various indoor conditions.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Arrow-shaped leaves with pink, cream, and green variegation",
    care_tips: {
      light: "Bright, indirect light. Can tolerate low light but may lose some variegation.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil consistently moist, allowing the top inch to dry out between waterings.",
      temperature: "Prefers temperatures between 60-75°F (15-24°C).",
      humidity: "Enjoys high humidity but can adapt to average indoor levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Prune regularly to maintain shape and encourage bushier growth. Rotate the plant for even light exposure."
    },
    long_description: "Pink Syngonium, or Arrowhead Plant, features arrow-shaped leaves with variegation in pink, cream, and green. It thrives in bright, indirect light and is ideal for hanging baskets or climbing."
  },
  {
    id: 50,
    plant_name: "Fiddle Leaf Fig",
    // image: Fiddle_Leaf_Fig,
    type: "indoor",
    description: "Fiddle Leaf Fig, or Ficus lyrata, is a popular indoor plant known for its large, glossy, violin-shaped leaves. The plant can grow quite tall, making it a striking focal point in any room. Fiddle Leaf Fig is relatively demanding in terms of care but offers a dramatic visual impact when maintained properly.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Large, glossy leaves shaped like violins",
    care_tips: {
      light: "Bright, indirect light. Can tolerate some direct morning sunlight.",
      soil: "Well-draining potting mix.",
      water: "Water when the top 1-2 inches of soil are dry. Avoid overwatering.",
      temperature: "Ideal temperatures are between 60-75°F (15-24°C).",
      humidity: "Prefers higher humidity levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Clean the leaves regularly to prevent dust buildup. Rotate the plant to ensure even growth."
    },
    long_description: "Fiddle Leaf Fig, or Ficus lyrata, features large, glossy leaves shaped like violins. It thrives in bright, indirect light and is prized for its dramatic visual impact in indoor settings."
  },


  {
    id: 51,
    plant_name: "Monstera",
    // image: Monstera,
    type: "indoor",
    description: "Monstera, also known as Swiss Cheese Plant, is famous for its large, glossy, perforated leaves that add a tropical vibe to any indoor space. The leaves develop unique holes and splits as the plant matures, giving it a distinctive look. Monstera is relatively easy to care for and can grow quite large under the right conditions.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Large, glossy leaves with distinctive holes and splits",
    care_tips: {
      light: "Bright, indirect light. Can tolerate low light but will grow slower.",
      soil: "Well-draining potting mix.",
      water: "Water when the top inch of soil is dry. Overwatering can lead to root rot.",
      temperature: "Thrives in temperatures between 65-85°F (18-29°C).",
      humidity: "Prefers higher humidity levels but can adapt to average indoor conditions.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Provide a moss pole or support for the plant to climb. Prune regularly to control size and shape."
    },
    long_description: "Monstera, or Swiss Cheese Plant, features large, glossy leaves with distinctive holes and splits. It thrives in bright, indirect light and adds a tropical touch to indoor spaces."
  },
  {
    id: 52,
    plant_name: "Green Areliya",
    // image: Green_Areliya,
    type: "indoor",
    description: "Green Areliya, commonly known as Ming Aralia, is an elegant houseplant with delicate, feathery leaves that create a lush, tropical appearance. The finely divided leaves are bright green and grow on slender, woody stems, giving the plant an airy and graceful look. Green Areliya is popular for its unique foliage and ability to add a touch of sophistication to any indoor space.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Delicate, feathery leaves on slender, woody stems",
    care_tips: {
      light: "Prefers bright, indirect light but can tolerate low light conditions.",
      soil: "Well-draining potting mix with good aeration.",
      water: "Water moderately, allowing the top inch of soil to dry out between waterings.",
      temperature: "Thrives in temperatures between 65-75°F (18-24°C).",
      humidity: "Prefers high humidity levels.",
      toxicity: "Non-toxic to pets and humans.",
      tip: "Mist the plant regularly to increase humidity. Prune to maintain shape and encourage bushier growth."
    },
    long_description: "Green Areliya, or Ming Aralia, features delicate, feathery leaves on slender, woody stems. It thrives in bright, indirect light and adds a touch of sophistication to indoor spaces."
  },
  {
    id: 53,
    plant_name: "White Syngonium",
    // image: White_Syngonium,
    type: "indoor",
    description: "White Syngonium, also known as Arrowhead Plant, is a versatile houseplant with arrow-shaped leaves that display a mix of white and green variegation. The plant's trailing habit makes it suitable for hanging baskets or as a climbing plant. White Syngonium is easy to care for and can adapt to a variety of indoor conditions.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Arrow-shaped leaves with white and green variegation",
    care_tips: {
      light: "Bright, indirect light. Can tolerate low light but may lose some variegation.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil consistently moist, allowing the top inch to dry out between waterings.",
      temperature: "Prefers temperatures between 60-75°F (15-24°C).",
      humidity: "Enjoys high humidity but can adapt to average indoor levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Prune regularly to maintain shape and encourage bushier growth. Rotate the plant for even light exposure."
    },
    long_description: "White Syngonium, or Arrowhead Plant, features arrow-shaped leaves with variegation in white and green. It thrives in bright, indirect light and is versatile for various indoor settings."
  },
  {
    id: 54,
    plant_name: "Dracaena Chocolate",
    // image: Dracaena_Chocolate,
    type: "indoor",
    description: "Dracaena Chocolate, also known as Dracaena Marginata 'Chocolate,' is a striking houseplant with long, slender leaves that have deep chocolate-brown margins. The leaves are green with a rich brown edge, giving the plant a dramatic and elegant appearance. This Dracaena variety is easy to care for and adds a touch of sophistication to any indoor space.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Long, slender leaves with deep chocolate-brown margins",
    care_tips: {
      light: "Prefers bright, indirect light but can tolerate low light.",
      soil: "Well-draining potting mix.",
      water: "Water when the top inch of soil is dry. Avoid overwatering.",
      temperature: "Thrives in temperatures between 65-80°F (18-27°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Wipe the leaves regularly to remove dust. Rotate the plant occasionally for even growth."
    },
    long_description: "Dracaena Chocolate, or Dracaena Marginata 'Chocolate,' features long, slender leaves with deep chocolate-brown margins. It thrives in bright, indirect light and adds a touch of elegance to indoor spaces."
  },
  {
    id: 55,
    plant_name: "Dracaena Mahatma",
    // image: Dracaena_Mahatma,
    type: "indoor",
    description: "Dracaena Mahatma is a unique variety of Dracaena with broad, dark green leaves that are slightly wavy and have a glossy finish. The plant has a robust, upright growth habit and is known for its low-maintenance nature. Dracaena Mahatma is an excellent choice for adding a touch of greenery to any indoor space.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Broad, dark green leaves with a glossy finish",
    care_tips: {
      light: "Bright, indirect light. Can tolerate low light but may grow slower.",
      soil: "Well-draining potting mix.",
      water: "Allow the top inch of soil to dry out between waterings.",
      temperature: "Prefers temperatures between 65-80°F (18-27°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Clean the leaves regularly to remove dust and ensure optimal photosynthesis. Avoid cold drafts and sudden temperature changes."
    },
    long_description: "Dracaena Mahatma features broad, dark green leaves with a glossy finish. It thrives in bright, indirect light and is valued for its robust growth and low-maintenance care."
  },

  {
    id: 56,
    plant_name: "Homalomena",
    // image: Homalomena,
    type: "indoor",
    description: "Homalomena is a beautiful houseplant known for its heart-shaped, glossy leaves that come in various shades of green. The plant has a compact growth habit and is relatively low-maintenance, making it a popular choice for indoor gardening. Homalomena adds a touch of elegance to any indoor space with its lush, vibrant foliage.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Heart-shaped, glossy leaves in shades of green",
    care_tips: {
      light: "Prefers bright, indirect light but can tolerate low light.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil consistently moist, allowing the top inch to dry out between waterings.",
      temperature: "Thrives in temperatures between 65-80°F (18-27°C).",
      humidity: "Prefers higher humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Mist the leaves regularly to increase humidity. Rotate the plant to ensure even growth."
    },
    long_description: "Homalomena features heart-shaped, glossy leaves in various shades of green. It thrives in bright, indirect light and adds elegance to indoor spaces."
  },
  {
    id: 57,
    plant_name: "Calla Lily Pot",
    // image: Calla_Lily_Pot,
    type: "indoor",
    description: "Calla Lily, or Zantedeschia, is a stunning flowering plant known for its elegant, trumpet-shaped blooms that come in a variety of colors, including white, yellow, pink, and purple. The plant has broad, glossy green leaves and is often grown in pots for its ornamental value. Calla Lilies are popular for their striking appearance and are often used in floral arrangements.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Trumpet-shaped blooms in various colors",
    care_tips: {
      light: "Bright, indirect light.",
      soil: "Well-draining potting mix with good moisture retention.",
      water: "Keep the soil consistently moist but not waterlogged.",
      temperature: "Prefers temperatures between 60-75°F (15-24°C).",
      humidity: "Enjoys moderate to high humidity levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Deadhead spent blooms to encourage new flowers. Provide support for the stems if necessary."
    },
    long_description: "Calla Lily, or Zantedeschia, features elegant, trumpet-shaped blooms in various colors. It thrives in bright, indirect light and is valued for its ornamental appeal in indoor settings."
  },
  {
    id: 58,
    plant_name: "Peace Lily",
    // image: Peace_Lily,
    type: "indoor",
    description: "Peace Lily, or Spathiphyllum, is a popular houseplant known for its elegant white flowers and lush, dark green leaves. The flowers are actually modified leaves called spathes that surround the central spadix. Peace Lilies are easy to care for and are excellent air purifiers, making them a great addition to any indoor space.",
    best_for: "Indoor environments with low to bright indirect light",
    highlight: "Elegant white flowers and lush, dark green leaves",
    care_tips: {
      light: "Low to bright indirect light.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil consistently moist, but allow the top inch to dry out between waterings.",
      temperature: "Thrives in temperatures between 65-80°F (18-27°C).",
      humidity: "Prefers higher humidity levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Wipe the leaves regularly to remove dust. Avoid direct sunlight, which can scorch the leaves."
    },
    long_description: "Peace Lily, or Spathiphyllum, features elegant white flowers and lush, dark green leaves. It thrives in low to bright indirect light and is valued for its air purifying qualities."
  },
  {
    id: 59,
    plant_name: "Anthurium",
    // image: Anthurium,
    type: "indoor",
    description: "Anthurium, also known as Flamingo Flower or Laceleaf, is a striking houseplant with glossy, heart-shaped leaves and long-lasting, vibrant flowers that come in red, pink, white, or purple. The flowers are actually spathes that surround a central spadix. Anthuriums are prized for their bold, exotic appearance and are relatively easy to care for.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Glossy, heart-shaped leaves and vibrant spathes",
    care_tips: {
      light: "Bright, indirect light. Avoid direct sunlight.",
      soil: "Well-draining potting mix with good aeration.",
      water: "Keep the soil consistently moist but not waterlogged. Allow the top inch to dry out between waterings.",
      temperature: "Prefers temperatures between 65-80°F (18-27°C).",
      humidity: "Thrives in high humidity.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Mist the plant regularly to increase humidity. Clean the leaves to remove dust and promote healthy growth."
    },
    long_description: "Anthurium, or Flamingo Flower, features glossy, heart-shaped leaves and vibrant spathes. It thrives in bright, indirect light and is prized for its exotic appearance in indoor environments."
  },
  {
    id: 60,
    plant_name: "Orchid Pot",
    // image: Orchid_Pot,
    type: "indoor",
    description: "Orchids are one of the most popular and diverse groups of flowering plants, known for their intricate and beautiful blooms. They come in various colors, shapes, and sizes, with some popular types including Phalaenopsis, Cattleya, and Dendrobium. Orchids are often grown in specialized orchid pots with good drainage and aeration to support their unique growing needs.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Intricate and beautiful blooms in various colors",
    care_tips: {
      light: "Bright, indirect light. Avoid direct sunlight.",
      soil: "Orchid-specific potting mix, typically made of bark, moss, and other well-draining materials.",
      water: "Water when the potting mix is nearly dry. Orchids prefer to dry out slightly between waterings.",
      temperature: "Prefers temperatures between 60-80°F (15-27°C).",
      humidity: "Enjoys high humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Avoid overwatering and ensure good air circulation around the roots. Provide a humidity tray or mist regularly to maintain humidity."
    },
    long_description: "Orchids are known for their intricate blooms and come in various colors. They thrive in bright, indirect light and are often grown in specialized orchid pots."
  }
  ,


  {
    id: 61,
    plant_name: "Fern",
    // image: Fern,
    type: "indoor",
    description: "Ferns are a diverse group of plants known for their lush, feathery fronds and ability to thrive in shady, humid environments. Common indoor ferns include Boston Fern, Maidenhair Fern, and Staghorn Fern. They add a touch of greenery and elegance to any indoor space and are appreciated for their delicate, airy foliage.",
    best_for: "Indoor environments with indirect light or partial shade",
    highlight: "Lush, feathery fronds",
    care_tips: {
      light: "Indirect light or partial shade.",
      soil: "Well-draining, moisture-retentive potting mix.",
      water: "Keep the soil consistently moist but not soggy. Avoid letting the soil dry out completely.",
      temperature: "Prefers temperatures between 60-75°F (15-24°C).",
      humidity: "Thrives in high humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Mist regularly to maintain humidity. Avoid placing ferns in dry, drafty areas. Prune any dead or yellowing fronds to promote new growth."
    },
    long_description: "Ferns are known for their lush, feathery fronds and thrive in shady, humid environments, making them ideal for indoor settings."
  },
  {
    id: 62,
    plant_name: "Aralia Palm",
    // image: Aralia_Palm,
    type: "indoor",
    description: "Aralia Palm, also known as Polyscias, is a tropical houseplant with delicate, fern-like leaves that grow in clusters on slender stems. The leaves are typically green or variegated with shades of white, cream, or yellow. Aralia Palm is valued for its graceful appearance and ability to thrive in low-light conditions, making it an excellent choice for indoor settings.",
    best_for: "Indoor environments with low to bright indirect light",
    highlight: "Graceful appearance and variegated leaves",
    care_tips: {
      light: "Low to bright indirect light. Can tolerate low light but may grow slower.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist, allowing the top inch to dry out between waterings.",
      temperature: "Prefers temperatures between 65-80°F (18-27°C).",
      humidity: "Enjoys higher humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Rotate the plant occasionally for even growth. Wipe the leaves with a damp cloth to remove dust."
    },
    long_description: "Aralia Palm, or Polyscias, features delicate, fern-like leaves and variegated colors. It thrives in low to bright indirect light and is valued for its graceful appearance in indoor environments."
  },
  {
    id: 63,
    plant_name: "Alocasia",
    //  image: Alocasia,
    type: "indoor",
    description: "Alocasia, also known as Elephant Ear or African Mask Plant, is a striking houseplant with large, arrowhead-shaped leaves that are often adorned with intricate patterns and colors. The foliage can range from deep green to purple-black with contrasting veins. Alocasia is prized for its dramatic appearance but requires careful attention to its growing conditions.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Large, arrowhead-shaped leaves with striking patterns",
    care_tips: {
      light: "Bright, indirect light. Avoid direct sunlight, which can scorch the leaves.",
      soil: "Well-draining potting mix with good aeration.",
      water: "Keep the soil consistently moist but not waterlogged. Allow the top inch to dry out between waterings.",
      temperature: "Prefers temperatures between 65-80°F (18-27°C).",
      humidity: "Requires high humidity levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Mist the leaves regularly to increase humidity. Protect from drafts and sudden temperature changes."
    },
    long_description: "Alocasia, or Elephant Ear, features large, arrowhead-shaped leaves with striking patterns. It thrives in bright, indirect light and adds dramatic flair to indoor spaces."
  },
  {
    id: 64,
    plant_name: "Rofis Palm",
    //  image: Rofis_Palm,
    type: "indoor",
    description: "Rofis Palm, also known as Rhapis Palm or Lady Palm, is a small, multi-stemmed palm with fan-shaped leaves that grow in clusters. The leaves are deep green and have a glossy texture, adding a touch of elegance to indoor spaces. Rofis Palm is appreciated for its compact size and air-purifying qualities.",
    best_for: "Indoor environments with low to moderate indirect light",
    highlight: "Fan-shaped leaves with a glossy texture",
    care_tips: {
      light: "Low to moderate indirect light. Can tolerate low light conditions.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist but not waterlogged. Allow the top inch to dry out between waterings.",
      temperature: "Prefers temperatures between 65-80°F (18-27°C).",
      humidity: "Enjoys higher humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Clean the leaves occasionally to remove dust. Prune dead or yellowing fronds to maintain appearance."
    },
    long_description: "Rofis Palm, or Rhapis Palm, features fan-shaped leaves with a glossy texture. It thrives in low to moderate indirect light and is valued for its compact size and air-purifying qualities."
  },
  {
    id: 65,
    plant_name: "Spider Plant",
    //  image: Spider_Plant,
    type: "indoor",
    description: "Spider Plant, or Chlorophytum comosum, is a popular houseplant known for its arching, narrow leaves that resemble spider legs. The leaves can be green or variegated with white stripes. Spider Plants produce small white flowers and baby plantlets, known as spiderettes, which dangle from long stems. They are easy to care for and adaptable to a variety of indoor conditions.",
    best_for: "Indoor environments with moderate to bright indirect light",
    highlight: "Arched, narrow leaves with spiderettes",
    care_tips: {
      light: "Moderate to bright indirect light. Can tolerate lower light levels.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist during the growing season. Allow the top inch to dry out between waterings in winter.",
      temperature: "Prefers temperatures between 60-75°F (15-24°C).",
      humidity: "Enjoys average indoor humidity levels.",
      toxicity: "Non-toxic to pets and humans.",
      tip: "Remove spiderettes to promote the plant's energy towards growth. Prune yellow leaves and occasionally mist the plant to increase humidity."
    },
    long_description: "Spider Plant, or Chlorophytum comosum, features arching, narrow leaves and produces small white flowers and baby plantlets. It thrives in moderate to bright indirect light and is easy to care for in various indoor conditions."
  }
  ,

  {
    id: 66,
    plant_name: "Ficus Lyrata",
    // image: Ficus_Lyrata,
    type: "indoor",
    description: "Ficus Lyrata, commonly known as Fiddle Leaf Fig, is a popular indoor tree with large, glossy, violin-shaped leaves that grow upright on woody stems. The leaves are dark green and can be up to 12 inches long. Fiddle Leaf Figs are prized for their dramatic foliage and can become a statement piece in any room when properly cared for.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Large, glossy leaves shaped like violins",
    care_tips: {
      light: "Bright, indirect light. Avoid direct sunlight, which can scorch the leaves.",
      soil: "Well-draining potting mix.",
      water: "Water when the top inch of soil is dry. Avoid overwatering, as it can lead to root rot.",
      temperature: "Prefers temperatures between 65-75°F (18-24°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Toxic to pets and humans if ingested.",
      tip: "Clean the leaves with a damp cloth to remove dust. Rotate the plant occasionally for even growth."
    },
    long_description: "Ficus Lyrata, or Fiddle Leaf Fig, features large, glossy leaves shaped like violins and thrives in bright, indirect light. It is prized for its dramatic foliage and can become a statement piece in indoor environments."
  },
  {
    id: 67,
    plant_name: "Lucky Bamboo",
    // image: Lucky_Bamboo,
    type: "indoor",
    description: "Lucky Bamboo, or Dracaena sanderiana, is not actually bamboo but a member of the Dracaena family. It features slender, green stalks with intricate arrangements of leaves and can be grown in water or soil. Lucky Bamboo is known for its symbolism of good fortune and is easy to care for, making it a popular choice for indoor gardens.",
    best_for: "Indoor environments with indirect light",
    highlight: "Symbol of good fortune",
    care_tips: {
      light: "Indirect light. Can tolerate low light conditions.",
      water: "For water-grown Lucky Bamboo, keep the roots submerged in water, changing it every 1-2 weeks. For soil-grown, keep the soil consistently moist.",
      temperature: "Prefers temperatures between 65-85°F (18-29°C).",
      humidity: "Enjoys higher humidity levels.",
      toxicity: "Non-toxic to pets and humans.",
      tip: "Trim yellow or damaged leaves. Use distilled or filtered water for water-grown Lucky Bamboo to avoid salt and chemical buildup."
    },
    long_description: "Lucky Bamboo, or Dracaena sanderiana, features slender green stalks and is known for its symbolism of good fortune. It thrives in indirect light and is easy to care for in both water and soil."
  },
  {
    id: 68,
    plant_name: "Black Ficus",
    // image: Black_Ficus,
    type: "indoor",
    description: "Black Ficus, or Ficus elastica 'Black,' is a striking variety of Rubber Plant with large, glossy leaves that are deep burgundy to almost black in color. The leaves have a waxy texture and grow on sturdy, upright stems. Black Ficus is appreciated for its bold foliage and can become a focal point in any indoor setting.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Large, glossy leaves in deep burgundy to black",
    care_tips: {
      light: "Bright, indirect light. Can tolerate some direct sunlight.",
      soil: "Well-draining potting mix.",
      water: "Allow the top inch of soil to dry out between waterings.",
      temperature: "Prefers temperatures between 65-75°F (18-24°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Mildly toxic if ingested by pets or humans.",
      tip: "Clean the leaves with a damp cloth to keep them free of dust. Rotate the plant occasionally for even growth."
    },
    long_description: "Black Ficus, or Ficus elastica 'Black,' features large, glossy leaves in deep burgundy to almost black coloration. It thrives in bright, indirect light and is valued for its bold foliage, making it a striking addition to indoor environments."
  },
  {
    id: 69,
    plant_name: "Pencil Point",
    //  image: Pencil_Point,
    type: "indoor",
    description: "Pencil Point, or Euphorbia tirucalli, is a unique succulent shrub with thin, pencil-like branches that grow upright and densely. The branches are green and turn reddish-orange in bright sunlight. Pencil Point is valued for its unusual appearance and low-maintenance nature, making it a great choice for indoor succulent collections.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Thin, pencil-like branches that grow upright",
    care_tips: {
      light: "Bright, indirect light. Can tolerate direct sunlight for short periods.",
      soil: "Well-draining succulent or cactus potting mix.",
      water: "Water sparingly, allowing the soil to dry out completely between waterings.",
      temperature: "Prefers temperatures between 65-75°F (18-24°C).",
      humidity: "Enjoys average indoor humidity levels.",
      toxicity: "Sap can be irritating to skin and eyes; keep away from pets and children.",
      tip: "Prune to maintain shape and size. Handle with care due to the toxic sap."
    },
    long_description: "Pencil Point, or Euphorbia tirucalli, features thin, pencil-like branches that grow upright and turn reddish-orange in bright sunlight. It thrives in bright, indirect light and is valued for its unique appearance and low-maintenance care requirements."
  }
  ,

  {
    id: 70,
    plant_name: "Panda Ficus",
    //  image: Panda_Ficus,
    type: "indoor",
    description: "Panda Ficus, also known as Ficus panda, is a compact and bushy houseplant with small, oval-shaped leaves that have a striking green and white variegation. The foliage resembles the markings of a panda, hence its name. Panda Ficus is valued for its decorative foliage and is relatively easy to care for indoors.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Green and white variegated leaves resembling panda markings",
    care_tips: {
      light: "Bright, indirect light. Can tolerate lower light conditions.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist during the growing season. Allow the top inch to dry out between waterings in winter.",
      temperature: "Prefers temperatures between 65-75°F (18-24°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Non-toxic to pets and humans.",
      tip: "Rotate the plant occasionally for even growth. Clean the leaves with a damp cloth to remove dust."
    },
    long_description: "Panda Ficus, also known as Ficus panda, features compact, bushy growth with small, oval-shaped leaves that are green and white variegated, resembling panda markings. It thrives in bright, indirect light and is valued for its decorative foliage."
  },
  {
    id: 71,
    plant_name: "Mini Rakatu",
    //  image: Mini_Rakatu,
    type: "indoor",
    description: "Rakatu is a type of palm tree that typically grows tall with feathery fronds. Mini Rakatu likely refers to a smaller variety or a young Rakatu plant suitable for indoor cultivation.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Compact palm suitable for indoor cultivation",
    care_tips: {
      light: "Bright, indirect light.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist, not soggy.",
      temperature: "Prefers temperatures between 65-75°F (18-24°C).",
      humidity: "Enjoys moderate to high humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Rotate occasionally for even growth. Clean leaves to remove dust."
    },
    long_description: "Mini Rakatu is likely a smaller or young variety of Rakatu palm suitable for indoor cultivation. It thrives in bright, indirect light and requires moderate to high humidity levels to grow well."
  },
  {
    id: 72,
    plant_name: "Asoka Gold",
    //  image: Asoka_Gold,
    type: "indoor",
    description: "Asoka Gold is likely a cultivar of Saraca asoca, also known as the Ashoka tree. It's valued for its golden or yellowish foliage.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Golden or yellowish foliage",
    care_tips: {
      light: "Bright, indirect light.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist, not waterlogged.",
      temperature: "Prefers temperatures between 65-75°F (18-24°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Prune regularly to maintain shape. Avoid sudden temperature changes."
    },
    long_description: "Asoka Gold is likely a cultivar of Saraca asoca, featuring golden or yellowish foliage. It thrives in bright, indirect light and requires moderate humidity levels to maintain its vibrant color."
  },
  {
    id: 73,
    plant_name: "Chota New",
    //  image: Chota_New,
    type: "indoor",
    description: "Unfortunately, I couldn't find specific information on a plant named 'Chota New.' It might be a local or regional name. If you have more details or another name, I could try to provide more accurate information.",
    care_tips: {
      light: "N/A",
      soil: "N/A",
      water: "N/A",
      temperature: "N/A",
      humidity: "N/A",
      toxicity: "N/A",
      tip: "N/A"
    },
    long_description: "I couldn't find specific information on 'Chota New.' It may be a regional or local name for a plant. If you have more details or another name, I can help find accurate information."
  },
  {
    id: 74,
    plant_name: "Ferns",
    //  image: Ferns,
    type: "indoor",
    description: "Ferns are a diverse group of plants known for their feathery fronds and love for moisture and shade.",
    best_for: "Indoor environments with indirect light or partial shade",
    highlight: "Feathery fronds and love for moisture and shade",
    care_tips: {
      light: "Indirect light or partial shade.",
      soil: "Well-draining, moisture-retentive potting mix.",
      water: "Keep the soil consistently moist.",
      temperature: "Prefers temperatures between 60-75°F (15-24°C).",
      humidity: "Thrives in high humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Mist regularly to maintain humidity. Avoid dry, drafty areas."
    },
    long_description: "Ferns are a diverse group of plants known for their feathery fronds and preference for moisture and shade. They thrive in high humidity levels and require consistent moisture in well-draining soil."
  },
  {
    id: 75,
    plant_name: "Pandanus (Assuming it's Pandanus)",
    //  image: Pandanus,
    type: "indoor",
    description: "Pandanus, also known as Screw Pine, is a tropical plant known for its long, spiky leaves arranged in a spiral pattern around the stem.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Long, spiky leaves arranged in a spiral pattern",
    care_tips: {
      light: "Bright, indirect light.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist, not waterlogged.",
      temperature: "Prefers temperatures between 65-80°F (18-27°C).",
      humidity: "Enjoys high humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Rotate occasionally for even growth. Mist leaves to maintain humidity."
    },
    long_description: "Pandanus, also known as Screw Pine, is a tropical plant valued for its long, spiky leaves arranged in a spiral pattern around the stem. It thrives in bright, indirect light and requires high humidity levels to grow well."
  }
  ,

  {
    id: 76,
    plant_name: "Madhumalti (Rangoon Creeper)",
    //  image: Madhumalti,
    type: "indoor",
    description: "Madhumalti, or Rangoon Creeper, is a flowering vine known for its fragrant, colorful blooms that change color as they mature.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Fragrant, colorful blooms that change color",
    care_tips: {
      light: "Bright, indirect light.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist, not soggy.",
      temperature: "Prefers temperatures between 65-75°F (18-24°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Provide support for climbing. Prune after flowering to promote new growth."
    },
    long_description: "Madhumalti, or Rangoon Creeper, is a flowering vine known for its fragrant blooms that change color as they mature. It thrives in bright, indirect light and requires moderate humidity levels to flourish."
  },
  {
    id: 77,
    plant_name: "Tagar (Tabernaemontana divaricata)",
    //  image: Tagar,
    type: "indoor",
    description: "Tagar, also known as Crepe Jasmine, is a shrub known for its fragrant white flowers that resemble crepe paper. It has glossy, dark green leaves and blooms throughout the year in tropical climates.",
    best_for: "Indoor environments with full sun to partial shade",
    highlight: "Fragrant white flowers resembling crepe paper",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining soil rich in organic matter.",
      water: "Keep the soil evenly moist, especially during the growing season.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Parts of the plant can be toxic if ingested.",
      tip: "Prune after flowering to maintain shape and encourage new growth."
    },
    long_description: "Tagar, also known as Crepe Jasmine, is a shrub prized for its fragrant white flowers and glossy green leaves. It thrives in full sun to partial shade and requires moderate humidity to bloom profusely."
  },
  {
    id: 78,
    plant_name: "Rudraksha (Elaeocarpus ganitrus)",
    // image: Rudraksha,
    type: "indoor",
    description: "Rudraksha is a large evergreen tree known for its distinctive blue fruits, which contain seeds that are used in traditional medicine and for making prayer beads. It has glossy leaves and grows in tropical and subtropical regions.",
    best_for: "Indoor environments with full sun to partial shade",
    highlight: "Distinctive blue fruits and glossy leaves",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining, sandy loam soil.",
      water: "Regular watering, allowing the soil to dry out slightly between waterings.",
      temperature: "Prefers temperatures between 65-90°F (18-32°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic, but ingestion of seeds should be avoided.",
      tip: "Protect young plants from strong winds. Fertilize during the growing season."
    },
    long_description: "Rudraksha is a large evergreen tree known for its distinctive blue fruits and glossy leaves. It thrives in full sun to partial shade and requires regular watering and moderate humidity to thrive indoors."
  },
  {
    id: 79,
    plant_name: "Diplotica Palm (Dypsis decaryi)",
    //  image: Diplotica_Palm,
    type: "indoor",
    description: "Diplotica Palm, also known as Triangle Palm, is a striking palm with leaves that form a triangle shape. It is native to Madagascar and is prized for its architectural appearance.",
    best_for: "Indoor environments with full sun to partial shade",
    highlight: "Architectural palm with triangular leaves",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining soil, slightly acidic to neutral.",
      water: "Water regularly, allowing the soil to dry out slightly between waterings.",
      temperature: "Prefers temperatures above 65°F (18°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Rotate the plant occasionally for even growth. Prune dead leaves to maintain appearance."
    },
    long_description: "Diplotica Palm, or Triangle Palm, is native to Madagascar and known for its architectural appearance with triangular leaves. It thrives in full sun to partial shade and requires well-draining soil and regular watering."
  },
  {
    id: 80,
    plant_name: "Ionitia Palm (Chamaedorea costaricana)",
    //  image: Ionitia_Palm,
    type: "indoor",
    description: "Ionitia Palm, also known as Costa Rican Bamboo Palm, is a graceful palm with bamboo-like stems and feathery, arching fronds. It is native to Central America and is popular as an indoor plant.",
    best_for: "Indoor environments with bright, indirect light",
    highlight: "Graceful palm with bamboo-like stems and arching fronds",
    care_tips: {
      light: "Bright, indirect light.",
      soil: "Well-draining potting mix.",
      water: "Keep the soil evenly moist, not soggy.",
      temperature: "Prefers temperatures between 65-75°F (18-24°C).",
      humidity: "Enjoys higher humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Mist leaves regularly to increase humidity. Fertilize during the growing season."
    },
    long_description: "Ionitia Palm, also known as Costa Rican Bamboo Palm, is a graceful palm with bamboo-like stems and arching fronds. It thrives in bright, indirect light and requires higher humidity levels to maintain its health and appearance indoors."
  }
  ,

  {
    id: 81,
    plant_name: "Badha Bambu (Bambusa vulgaris)",
    //  image: Badha_Bambu,
    type: "outdoor",
    description: "Badha Bambu, commonly known as Golden Bamboo, is a fast-growing bamboo species with tall, upright stems and golden-yellow culms. It is native to Southeast Asia and is cultivated for its ornamental value and multiple uses.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Fast-growing with golden-yellow culms",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining soil, rich in organic matter.",
      water: "Keep the soil consistently moist, especially during hot weather.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Prefers moderate to high humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Control spreading by using barriers or containers. Prune to remove dead or weak stems."
    },
    long_description: "Badha Bambu, or Golden Bamboo, is prized for its fast growth and distinctive golden-yellow culms. It thrives in outdoor environments with moderate to high humidity levels and requires regular pruning to maintain its vigor."
  },
  {
    id: 82,
    plant_name: "Chafa Grafted (Michelia champaca)",
    //  image: Chafa_Grafted,
    type: "outdoor",
    description: "Chafa Grafted, also known as Champak or Joy Perfume Tree, is a small to medium-sized evergreen tree with fragrant yellow or orange flowers. It is native to South Asia and Southeast Asia and is prized for its aromatic flowers used in perfumery.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Fragrant yellow or orange flowers",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining, fertile soil.",
      water: "Water regularly, keeping the soil evenly moist.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Prune after flowering to maintain shape and promote new growth."
    },
    long_description: "Chafa Grafted, or Champak, is cherished for its fragrant yellow or orange flowers and glossy green foliage. It thrives in outdoor gardens with regular watering and benefits from pruning to enhance flowering."
  },
  {
    id: 83,
    plant_name: "Golden Cupros (Cupressus macrocarpa 'Goldcrest')",
    //  image: Golden_Cupros,
    type: "outdoor",
    description: "Golden Cupros, also known as Goldcrest Cypress or Lemon Cypress, is a small evergreen tree with bright yellow-green foliage that resembles flattened sprays. It is popular for its vibrant color and citrusy fragrance.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Bright yellow-green foliage with a citrusy fragrance",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining soil, slightly acidic to neutral.",
      water: "Keep the soil evenly moist, not waterlogged.",
      temperature: "Prefers temperatures between 60-75°F (15-24°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Protect from drying winds. Prune lightly to maintain shape and size."
    },
    long_description: "Golden Cupros, or Goldcrest Cypress, is prized for its bright yellow-green foliage and refreshing citrusy fragrance. It thrives in outdoor gardens with moderate humidity and benefits from occasional pruning to maintain its compact shape."
  },
  {
    id: 84,
    plant_name: "Izora Small (Ixora coccinea)",
    // image: Izora_Small,
    type: "outdoor",
    description: "Izora Small, also known as Jungle Geranium or Flame of the Woods, is a tropical shrub with clusters of small, colorful flowers in shades of red, orange, yellow, or pink. It has glossy, dark green leaves and blooms throughout the year in warm climates.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Colorful clusters of small flowers",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining, slightly acidic soil.",
      water: "Keep the soil evenly moist, especially during flowering.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Prune after flowering to promote new growth. Fertilize during the growing season."
    },
    long_description: "Izora Small, or Jungle Geranium, is beloved for its colorful clusters of small flowers and glossy green foliage. It thrives in outdoor gardens with regular watering and occasional pruning to encourage continuous blooming."
  },
  {
    id: 85,
    plant_name: "Euforbia (Euphorbia)",
    // image: Euforbia,
    type: "outdoor",
    description: "Euforbia (Euphorbia), commonly referred to as Spurge, is a large genus of succulent plants with diverse forms and appearances. They range from low-growing ground covers to tall, cactus-like shrubs and are known for their unique shapes and colorful bracts.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Diverse forms with unique shapes and colorful bracts",
    care_tips: {
      light: "Full sun to partial shade, depending on the species.",
      soil: "Well-draining soil, such as cactus mix for succulent varieties.",
      water: "Water sparingly, allowing the soil to dry out between waterings.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Sap can be irritating to skin and toxic if ingested; handle with care.",
      tip: "Protect from frost and overwatering. Prune to maintain shape and remove dead growth."
    },
    long_description: "Euforbia, or Spurge, is a diverse genus of succulent plants prized for their unique shapes and colorful bracts. They thrive in outdoor gardens with well-draining soil and benefit from occasional pruning to maintain their health and appearance."
  }
  ,

  {
    id: 86,
    plant_name: "Royal Palm (Roystonea regia)",
    //  image: Royal_plam,
    type: "outdoor",
    description: "Royal Palm is a majestic, tall palm tree with a smooth, grey trunk and large, pinnate leaves that arch gracefully from the crown. It is native to tropical regions and is often used as an ornamental tree in landscapes.",
    best_for: "Outdoor landscapes or gardens",
    highlight: "Majestic appearance with large, arching leaves",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining soil.",
      water: "Water regularly, allowing the top inch of soil to dry out between waterings.",
      temperature: "Prefers temperatures above 65°F (18°C).",
      humidity: "Enjoys moderate to high humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Plant in a spacious area with plenty of room for growth. Provide adequate water during dry periods."
    },
    long_description: "Royal Palm is distinguished by its tall stature, smooth grey trunk, and large, gracefully arching leaves. It thrives in full sun and well-draining soil, making it an excellent choice for adding a majestic touch to outdoor landscapes."
  },
  {
    id: 87,
    plant_name: "Tebul Palm (Corypha umbraculifera)",
    //  image:Tebul_palm,
    type: "outdoor",
    description: "Tebul Palm, also known as Talipot Palm, is a massive palm native to Sri Lanka and southern India. It has a single trunk that can reach up to 25 meters in height with a crown of immense, fan-shaped leaves. Tebul Palm is notable for its size and the large inflorescence it produces near the end of its life.",
    best_for: "Large outdoor landscapes or gardens",
    highlight: "Massive size with fan-shaped leaves",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining soil.",
      water: "Keep the soil evenly moist, especially during hot weather.",
      temperature: "Prefers temperatures above 70°F (21°C).",
      humidity: "Enjoys high humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Plant in a spacious area with room for the large crown. Provide protection from strong winds."
    },
    long_description: "Tebul Palm, or Talipot Palm, is renowned for its massive size and impressive fan-shaped leaves. It requires ample space in large outdoor landscapes and benefits from regular watering and protection from strong winds."
  },
  {
    id: 88,
    plant_name: "Chafa (Michelia champaca)",
    //  image: Chafa,
    type: "outdoor",
    description: "Chafa, also known as Champak or Joy Perfume Tree, is a medium-sized evergreen tree with fragrant yellow or orange flowers. It is native to South Asia and Southeast Asia and is prized for its aromatic flowers used in perfumery.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Fragrant yellow or orange flowers",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining, fertile soil.",
      water: "Water regularly, keeping the soil evenly moist.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Prune after flowering to maintain shape and promote new growth."
    },
    long_description: "Chafa, or Champak, is cherished for its fragrant yellow or orange flowers and glossy green foliage. It thrives in outdoor gardens with regular watering and benefits from pruning to enhance flowering."
  },
  {
    id: 89,
    plant_name: "Bogen Wel (Bougainvillea)",
    //  image: Bogen_wel,
    type: "outdoor",
    description: "Bogen Wel, commonly known as Bougainvillea, is a tropical vine with vibrant clusters of papery bracts surrounding tiny, insignificant flowers. It is valued for its profuse, colorful blooms and ability to thrive in warm climates.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Vibrant clusters of papery bracts",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining soil.",
      water: "Allow the soil to dry out slightly between waterings.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Prune after flowering to promote new growth. Provide support for climbing varieties."
    },
    long_description: "Bogen Wel, or Bougainvillea, is prized for its vibrant clusters of papery bracts and its ability to thrive in warm climates. It adds a splash of color to outdoor gardens and benefits from occasional pruning to maintain its vigor."
  },
  {
    id: 90,
    plant_name: "Bakul (Mimusops elengi)",
    //  image: Bakul,
    type: "outdoor",
    description: "Bakul is a medium-sized evergreen tree with fragrant, small, white flowers that are highly aromatic, especially at night. It is native to South Asia and is often planted near temples and in gardens for its cultural significance and pleasant fragrance.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Highly aromatic, small white flowers",
    care_tips: {
      light: "Full sun to partial shade.",
      soil: "Well-draining, fertile soil.",
      water: "Keep the soil evenly moist, especially during the growing season.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Prune to maintain shape and remove dead or damaged branches. Fertilize during the growing season."
    },
    long_description: "Bakul is esteemed for its medium-sized stature and highly aromatic, small white flowers that emit a pleasant fragrance, particularly at night. It thrives in outdoor gardens with regular watering and occasional pruning to enhance its appearance."
  }
  ,


  {
    id: 91,
    plant_name: "Krushnakamal (Nymphaea nouchali)",
    // image : Krushnakamal,
    type: "outdoor",
    description: "Krushnakamal, also known as Blue Lotus or Star Lotus, is an aquatic plant with large, round leaves and striking blue or purple flowers. It grows in shallow, still waters and is revered in various cultures for its spiritual and medicinal properties.",
    best_for: "Aquatic gardens or ponds",
    highlight: "Striking blue or purple flowers",
    care_tips: {
      light: "Full sun.",
      soil: "Aquatic soil or loamy soil with organic matter.",
      water: "Keep roots submerged in shallow water (1-2 feet deep).",
      temperature: "Prefers temperatures above 70°F (21°C).",
      humidity: "N/A (aquatic plant).",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Provide a warm, sunny location. Fertilize sparingly during the growing season."
    },
    long_description: "Krushnakamal, or Blue Lotus, is cherished for its large, round leaves and striking blue or purple flowers that adorn shallow, still waters. It thrives in full sun and requires submerged roots in aquatic or loamy soil."
  },
  {
    id: 92,
    plant_name: "Lambuyo (Pachira aquatica)",
    // image: Lambuyo,
    type: "indoor",
    description: "Lambuyo, also known as Money Tree or Guiana Chestnut, is a tropical tree with a braided trunk and shiny, green leaves. It is often grown indoors as a symbol of good luck and prosperity.",
    best_for: "Indoor spaces with bright light",
    highlight: "Braided trunk and shiny, green leaves",
    care_tips: {
      light: "Bright, indirect light to full sun.",
      soil: "Well-draining, rich soil.",
      water: "Keep the soil evenly moist, allowing the top inch to dry out between waterings.",
      temperature: "Prefers temperatures above 65°F (18°C).",
      humidity: "Enjoys moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Rotate the plant occasionally for even growth. Prune to maintain the desired shape."
    },
    long_description: "Lambuyo, or Money Tree, is prized for its braided trunk and shiny, green leaves that bring a touch of prosperity to indoor spaces. It thrives in bright, indirect light and benefits from regular watering to keep its soil moist."
  },
  {
    id: 93,
    plant_name: "Kanda (Allium cepa)",
    // image: Kanda,
    type: "outdoor",
    description: "Kanda, also known as Onion, is a bulbous plant cultivated worldwide for its edible bulb. It has hollow, tubular leaves and produces spherical bulbs with layers of fleshy scales.",
    best_for: "Edible gardens or vegetable patches",
    highlight: "Cultivated for its edible bulb",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining, fertile soil.",
      water: "Keep the soil evenly moist during the growing season.",
      temperature: "Prefers temperatures above 50°F (10°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans (bulb is edible).",
      tip: "Plant bulbs in late winter or early spring for best results. Harvest when the tops begin to yellow and fall over."
    },
    long_description: "Kanda, or Onion, is renowned for its edible bulb cultivated in gardens worldwide. It thrives in full sun and well-draining soil, making it a staple in vegetable patches and edible gardens."
  },
  {
    id: 94,
    plant_name: "Tulsi (Ocimum tenuiflorum)",
    //  image: Tulsi,
    type: "outdoor",
    description: "Tulsi, also known as Holy Basil, is a sacred plant in Hindu culture and a popular culinary herb. It has aromatic leaves with a strong, spicy fragrance and is used in cooking, herbal teas, and traditional medicine.",
    best_for: "Herb gardens or medicinal gardens",
    highlight: "Sacred plant with aromatic leaves",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining, fertile soil.",
      water: "Keep the soil evenly moist, not waterlogged.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Pinch off flowers to promote leaf growth. Harvest leaves regularly for culinary use."
    },
    long_description: "Tulsi, or Holy Basil, holds significant cultural importance and is cherished for its aromatic leaves used in cooking and traditional medicine. It thrives in full sun and well-draining soil, making it ideal for herb and medicinal gardens."
  },
  {
    id: 95,
    plant_name: "Jaswand (Hibiscus rosa-sinensis)",
    //  image: Jaswand,
    type: "outdoor",
    description: "Jaswand, also known as Hibiscus or China Rose, is a tropical flowering shrub with large, colorful blooms in shades of red, pink, white, or yellow. It is popular in gardens and landscapes for its ornamental value and medicinal uses.",
    best_for: "Gardens or landscapes",
    highlight: "Large, colorful blooms",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining, fertile soil.",
      water: "Keep the soil evenly moist, especially during flowering.",
      temperature: "Prefers temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Prune to shape and encourage new growth. Fertilize during the growing season for best bloom."
    },
    long_description: "Jaswand, or Hibiscus, adds a splash of color to gardens with its large, vibrant blooms in various shades. It thrives in full sun and well-draining soil, making it a favorite for landscaping and garden enthusiasts."
  }
  ,

  {
    id: 96,
    plant_name: "Amba Kesar (Mangifera indica 'Kesar')",
    //  image: Amba_kesar,
    type: "outdoor",
    description: "Amba Kesar, commonly known as Kesar Mango, is a variety of mango known for its distinct sweet fragrance, vibrant saffron-colored flesh, and rich, sweet flavor. It is prized for its culinary uses and is cultivated primarily in the Gujarat region of India.",
    best_for: "Cultivation in regions with warm climates",
    highlight: "Distinct sweet fragrance and saffron-colored flesh",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining, fertile soil.",
      water: "Water regularly, allowing the soil to dry out slightly between waterings.",
      temperature: "Prefers warm temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Protect young plants from frost. Prune to maintain shape and promote fruiting."
    },
    long_description: "Amba Kesar, or Kesar Mango, is celebrated for its sweet fragrance, vibrant saffron-colored flesh, and rich flavor, making it a prized variety in culinary circles. It thrives in warm climates and requires regular watering and well-draining soil."
  },
  {
    id: 97,
    plant_name: "Amba Mallika (Mangifera indica 'Mallika')",
    //  image: Amba_mallika,
    type: "outdoor",
    description: "Amba Mallika is another variety of mango known for its sweet, aromatic flavor and fiberless flesh. It is a hybrid mango variety developed in India, combining traits of Neelum and Dasheri mangoes.",
    best_for: "Cultivation in regions with warm climates",
    highlight: "Sweet, aromatic flavor and fiberless flesh",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining, fertile soil.",
      water: "Keep the soil evenly moist, especially during fruit development.",
      temperature: "Prefers warm temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Fertilize with a balanced fertilizer during the growing season. Prune to improve air circulation and promote fruiting."
    },
    long_description: "Amba Mallika is renowned for its sweet, aromatic flavor and lack of fibers in its flesh, making it a favored mango variety. It thrives in warm climates and benefits from regular watering and well-draining soil."
  },
  {
    id: 98,
    plant_name: "Chiku Cricket Ball (Manilkara zapota)",
    //  image: Chicu_cricket_ball,
    type: "outdoor",
    description: "Chiku, also known as Sapodilla or Cricket Ball, is a tropical fruit tree known for its sweet, brownish fruit with a texture similar to pear. It is native to Mexico, Central America, and the Caribbean and is cultivated for its edible fruit.",
    best_for: "Tropical and subtropical climates",
    highlight: "Sweet, brownish fruit with pear-like texture",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining, sandy soil.",
      water: "Water regularly, allowing the soil to dry out slightly between waterings.",
      temperature: "Prefers warm temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Protect from frost. Prune to shape and promote fruiting."
    },
    long_description: "Chiku, or Sapodilla, is prized for its sweet, brownish fruit with a texture akin to pears. It thrives in tropical and subtropical climates, requiring regular watering and well-draining sandy soil."
  },
  {
    id: 99,
    plant_name: "Amba Dalim (Punica granatum)",
    //  image: Amba_dalim,
    type: "outdoor",
    description: "Amba Dalim, also known as Pomegranate, is a fruit-bearing shrub or small tree with glossy green leaves and vibrant red-orange flowers. It produces round, leathery-skinned fruit filled with juicy, edible seeds.",
    best_for: "Gardens or orchards",
    highlight: "Vibrant red-orange flowers and juicy, edible seeds",
    care_tips: {
      light: "Full sun.",
      soil: "Well-draining, fertile soil.",
      water: "Water regularly, allowing the soil to dry out slightly between waterings.",
      temperature: "Prefers warm temperatures above 60°F (15°C).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Generally non-toxic to pets and humans.",
      tip: "Prune to maintain shape and promote fruiting. Fertilize with a balanced fertilizer during the growing season."
    },
    long_description: "Amba Dalim, or Pomegranate, is known for its glossy green leaves, vibrant red-orange flowers, and delicious, juicy seeds enclosed in leathery skin. It thrives in full sun and well-draining soil, making it ideal for gardens or orchards."
  }
  ,


  {
    id: 100,
    plant_name: "Red Chhota Kohinoor (Codiaeum variegatum)",
    type: "indoor",
    description: "Red Chhota Kohinoor is a compact and bushy plant with vibrant red and green leaves. It is a type of Croton, known for its colorful foliage patterns and easy care.",
    best_for: "Indoor spaces with bright, indirect light",
    highlight: "Vibrant red and green leaves",
    care_tips: {
      light: "Prefers bright, indirect light. Can tolerate some direct sunlight but avoid intense afternoon sun.",
      soil: "Well-draining potting mix rich in organic matter.",
      water: "Water thoroughly when the top inch of soil feels dry. Reduce watering in winter.",
      temperature: "Ideal temperatures range between 18-24°C (65-75°F). Avoid cold drafts.",
      humidity: "Prefers moderate to high humidity levels. Mist the leaves occasionally.",
      toxicity: "Mildly toxic if ingested. Keep away from pets and children.",
      tip: [
        "Trim leggy growth to encourage bushiness.",
        "Rotate the plant occasionally for even growth.",
        "Wipe leaves gently with a damp cloth to keep them clean and dust-free."
      ]
    },
    long_description: "Red Chhota Kohinoor, also known as Codiaeum variegatum, is prized for its vibrant red and green leaves, adding a splash of color to indoor spaces. It thrives in bright, indirect light and requires regular watering and occasional misting for optimal growth."
  },
  {
    id: 101,
    plant_name: "Golden Fern Plant (Asplenium nidus variety)",
    type: "indoor",
    description: "Golden Fern features bright green, glossy fronds that emerge from a central rosette. It adds a tropical touch to indoor spaces.",
    best_for: "Indoor spaces with bright, indirect light",
    highlight: "Bright green, glossy fronds",
    care_tips: {
      light: "Thrives in bright, indirect light. Avoid direct sun to prevent leaf scorch.",
      soil: "Well-draining potting mix with good moisture retention.",
      water: "Keep soil consistently moist but not waterlogged. Water more sparingly in winter.",
      temperature: "Prefers temperatures between 18-24°C (65-75°F). Avoid cold drafts.",
      humidity: "Requires high humidity. Mist regularly or use a humidity tray.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Provide adequate humidity by placing the pot on a tray filled with pebbles and water.",
        "Fertilize lightly during the growing season to promote healthy growth."
      ]
    },
    long_description: "The Golden Fern, a variety of Asplenium nidus, showcases bright green, glossy fronds emerging from a central rosette. Ideal for indoor environments with bright, indirect light, it thrives with consistent moisture and high humidity levels."
  },
  {
    id: 102,
    plant_name: "Amla Bonsai (Emblica officinalis)",
    type: "outdoor",
    description: "Amla, or Indian Gooseberry, is a small tree often grown as a bonsai for its medicinal fruits. It has small, light green leaves and produces edible, sour berries.",
    best_for: "Outdoor cultivation as a bonsai",
    highlight: "Medicinal fruits and small, light green leaves",
    care_tips: {
      light: "Prefers full sun to partial shade.",
      soil: "Well-draining bonsai soil mix with good moisture retention.",
      water: "Keep soil evenly moist during the growing season. Reduce watering in winter.",
      temperature: "Thrives in temperatures between 20-30°C (68-86°F).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Prune regularly to maintain bonsai shape.",
        "Fertilize during the growing season with balanced fertilizer.",
        "Protect from frost and cold drafts."
      ]
    },
    long_description: "Amla, or Emblica officinalis, is revered for its medicinal fruits and is often cultivated as a bonsai. It features small, light green leaves and thrives in outdoor settings with ample sunlight and well-draining soil."
  },
  {
    id: 103,
    plant_name: "Pendanus Grass (Pandanus spp.)",
    type: "indoor/outdoor",
    description: "Pandanus, or Screw Pine, is a tropical plant with long, narrow, spiky leaves arranged in a spiral around the stem. It is known for its unique appearance and tropical vibes.",
    best_for: "Indoor or outdoor settings with bright, indirect light",
    highlight: "Long, narrow, spiky leaves in a spiral arrangement",
    care_tips: {
      light: "Prefers bright, indirect light. Can tolerate some direct sun.",
      soil: "Well-draining, sandy soil mix.",
      water: "Keep soil lightly moist. Allow the top inch of soil to dry out between waterings.",
      temperature: "Thrives in warm temperatures above 20°C (68°F).",
      humidity: "Prefers high humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Rotate the plant occasionally for even growth.",
        "Mist the leaves regularly to increase humidity.",
        "Prune dead or yellowing leaves to maintain appearance."
      ]
    },
    long_description: "Pandanus, or Screw Pine, features long, narrow, spiky leaves arranged in a distinctive spiral pattern around the stem. It thrives in bright, indirect light and requires regular watering and high humidity for optimal growth."
  },
  {
    id: 104,
    plant_name: "Viburnum Opulus (European Cranberrybush)",
    type: "outdoor",
    description: "Viburnum Opulus is a deciduous shrub with maple-like leaves and clusters of white flowers that turn into red berries in fall.",
    best_for: "Outdoor gardens or landscapes",
    highlight: "Clusters of white flowers turning into red berries",
    care_tips: {
      light: "Prefers full sun to partial shade.",
      soil: "Moist, well-draining soil.",
      water: "Keep soil evenly moist, especially during hot periods.",
      temperature: "Thrives in temperate climates.",
      humidity: "Adaptable to different humidity levels.",
      toxicity: "Berries may be toxic if ingested in large quantities.",
      tip: [
        "Prune after flowering to maintain shape.",
        "Mulch around the base to retain moisture.",
        "Fertilize in spring with balanced fertilizer."
      ]
    },
    long_description: "Viburnum Opulus, also known as European Cranberrybush, is a deciduous shrub prized for its maple-like leaves and clusters of white flowers that develop into red berries by fall. It thrives in outdoor gardens with ample sunlight and well-draining soil."
  }

  ,
  {
    id: 105,
    plant_name: "Conocarpus Tree (Conocarpus erectus)",
    type: "outdoor",
    description: "Conocarpus is an evergreen tree with dense foliage and small, inconspicuous flowers. It is commonly used for hedges and landscaping in coastal areas.",
    best_for: "Outdoor hedges and landscaping in coastal areas",
    highlight: "Dense foliage and tolerance to coastal conditions",
    care_tips: {
      light: "Prefers full sun to partial shade.",
      soil: "Well-draining soil.",
      water: "Moderate watering; tolerates drought once established.",
      temperature: "Prefers warm temperatures but can tolerate some cold.",
      humidity: "Tolerates varying humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Prune regularly to maintain desired shape.",
        "Protect from strong winds and salt spray in coastal areas.",
        "Fertilize in spring and summer with balanced fertilizer."
      ]
    },
    long_description: "Conocarpus, or Conocarpus erectus, is an evergreen tree valued for its dense foliage and suitability for hedges and landscaping in coastal regions. It thrives in full sun to partial shade and requires moderate watering, making it ideal for coastal gardens."
  },
  {
    id: 106,
    plant_name: "Karonda (Carissa carandas)",
    type: "outdoor",
    description: "Karonda is a small, evergreen shrub with glossy leaves and small, edible berries. It is often grown for its ornamental value and culinary use.",
    best_for: "Outdoor gardens and ornamental use",
    highlight: "Glossy leaves and edible berries",
    care_tips: {
      light: "Prefers full sun to partial shade.",
      soil: "Well-draining, fertile soil.",
      water: "Keep soil evenly moist, especially during fruiting.",
      temperature: "Thrives in warm temperatures.",
      humidity: "Tolerates varying humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Prune after flowering to maintain shape.",
        "Fertilize in spring and summer with balanced fertilizer.",
        "Protect from frost and cold drafts."
      ]
    },
    long_description: "Karonda, scientifically known as Carissa carandas, is a small evergreen shrub admired for its glossy leaves and small, edible berries. It is well-suited for outdoor gardens where it thrives in full sun to partial shade, requiring regular watering and occasional fertilization."
  },
  {
    id: 107,
    plant_name: "Croton Gold Dust (Codiaeum variegatum)",
    type: "indoor",
    description: "Croton Gold Dust is a colorful cultivar of Croton with shiny, leathery leaves splashed with yellow, orange, and red spots. It is prized for its vibrant foliage.",
    best_for: "Indoor spaces with bright, indirect light",
    highlight: "Colorful, shiny leaves with yellow, orange, and red spots",
    care_tips: {
      light: "Prefers bright, indirect light. Can tolerate some direct sun.",
      soil: "Well-draining potting mix.",
      water: "Keep soil evenly moist during the growing season. Reduce watering in winter.",
      temperature: "Thrives in temperatures between 18-24°C (65-75°F).",
      humidity: "Prefers high humidity levels.",
      toxicity: "Mildly toxic if ingested. Keep away from pets and children.",
      tip: [
        "Rotate the plant occasionally for even growth.",
        "Wipe leaves gently with a damp cloth to keep them clean and dust-free.",
        "Prune leggy growth to maintain compactness."
      ]
    },
    long_description: "Croton Gold Dust, a colorful variety of Codiaeum variegatum, is cherished for its shiny, leathery leaves adorned with yellow, orange, and red spots. It thrives in bright, indirect light and requires consistent moisture and occasional pruning for optimal growth."
  },
  {
    id: 108,
    plant_name: "Salvia (Salvia spp.)",
    type: "outdoor",
    description: "Salvia, commonly known as Sage, is a genus of aromatic herbaceous plants with spikes of blue, purple, pink, or white flowers. It is popular in gardens and as a culinary herb.",
    best_for: "Outdoor gardens and culinary herb use",
    highlight: "Aromatic foliage and colorful flower spikes",
    care_tips: {
      light: "Prefers full sun.",
      soil: "Well-draining soil.",
      water: "Allow soil to dry out between waterings.",
      temperature: "Thrives in warm temperatures.",
      humidity: "Tolerates varying humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Prune after flowering to promote new growth.",
        "Harvest leaves for culinary use.",
        "Fertilize in spring with balanced fertilizer."
      ]
    },
    long_description: "Salvia, a genus encompassing aromatic herbaceous plants, features spikes of blue, purple, pink, or white flowers. It thrives in outdoor gardens where it adds color and fragrance, requiring minimal watering and occasional fertilization for healthy growth."
  },
  {
    id: 109,
    plant_name: "Telopea truncata (Tasmanian Waratah)",
    type: "outdoor",
    description: "Telopea truncata, commonly known as Tasmanian Waratah, is a large shrub or small tree native to Australia. It is known for its striking, large red flowers and leathery foliage.",
    best_for: "Outdoor gardens and native plant landscapes",
    highlight: "Large red flowers and native Australian origin",
    care_tips: {
      light: "Prefers full sun to partial shade.",
      soil: "Well-draining, acidic soil.",
      water: "Keep soil evenly moist.",
      temperature: "Thrives in cool to moderate temperatures.",
      humidity: "Tolerates varying humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Protect from strong winds and frost.",
        "Prune after flowering to maintain shape.",
        "Fertilize with low-phosphorus fertilizer."
      ]
    },
    long_description: "Telopea truncata, commonly known as Tasmanian Waratah, is prized for its large red flowers and leathery foliage. Native to Australia, it thrives in outdoor gardens with acidic, well-draining soil and benefits from regular watering and protection from harsh weather conditions."
  },
  {
    id: 110,
    plant_name: "Chrysogonum Virginianum (Green and Gold)",
    type: "outdoor",
    description: "Chrysogonum virginianum, or Green and Gold, is a low-growing perennial with bright green, scalloped leaves and yellow, daisy-like flowers. It is valued for its ground-covering properties.",
    best_for: "Outdoor gardens and ground-covering landscapes",
    highlight: "Bright green leaves and yellow, daisy-like flowers",
    care_tips: {
      light: "Prefers partial shade to full shade.",
      soil: "Moist, well-draining soil.",
      water: "Keep soil evenly moist.",
      temperature: "Thrives in cool to moderate temperatures.",
      humidity: "Tolerates varying humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Divide clumps every few years to rejuvenate growth.",
        "Mulch around plants to retain moisture.",
        "Fertilize in spring with balanced fertilizer."
      ]
    },
    long_description: "Chrysogonum virginianum, known as Green and Gold, is prized for its bright green, scalloped leaves and cheerful yellow, daisy-like flowers. It thrives in outdoor gardens with partial to full shade, requiring regular watering and occasional fertilization for lush ground-covering growth."
  }
  ,


  {
    id: 111,
    plant_name: "Amba Haldi (Curcuma amada)",
    type: "outdoor",
    description: "Amba Haldi, also known as Mango Ginger, is a perennial herb related to turmeric. It has large, green leaves and produces cone-like yellow flowers. The rhizomes have a tangy, mango-like flavor and are used in culinary dishes and traditional medicine.",
    best_for: "Outdoor gardens and culinary use",
    highlight: "Large, green leaves and cone-like yellow flowers",
    care_tips: {
      light: "Prefers partial shade to full shade.",
      soil: "Well-draining, rich soil.",
      water: "Keep soil consistently moist, especially during active growth.",
      temperature: "Thrives in warm temperatures, around 20-30°C (68-86°F).",
      humidity: "Prefers moderate humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Harvest rhizomes as needed for culinary or medicinal purposes.",
        "Mulch around the plant to retain moisture and suppress weeds.",
        "Fertilize in spring and summer with organic fertilizer."
      ]
    },
    long_description: "Amba Haldi, scientifically known as Curcuma amada, is a perennial herb valued for its large, green leaves and cone-like yellow flowers. It thrives in outdoor gardens with rich, well-draining soil and requires consistent moisture and occasional fertilization for optimal growth."
  },
  {
    id: 112,
    plant_name: "Rhapis (Rhapis excelsa)",
    type: "indoor",
    description: "Rhapis, commonly known as Lady Palm, is a slow-growing palm with multiple slender trunks covered in brown fibers. It has fan-shaped, glossy green leaves that add a tropical touch to indoor spaces.",
    best_for: "Indoor spaces with bright, indirect light",
    highlight: "Slender trunks and fan-shaped, glossy green leaves",
    care_tips: {
      light: "Prefers bright, indirect light. Can tolerate lower light conditions.",
      soil: "Well-draining potting mix.",
      water: "Keep soil evenly moist. Allow the top inch of soil to dry out between waterings.",
      temperature: "Thrives in temperatures between 18-24°C (65-75°F).",
      humidity: "Prefers moderate to high humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Wipe leaves gently with a damp cloth to keep them clean.",
        "Repot every 2-3 years to refresh soil nutrients.",
        "Fertilize monthly during the growing season with balanced fertilizer."
      ]
    },
    long_description: "Rhapis excelsa, commonly known as Lady Palm, is a slow-growing palm appreciated for its slender trunks covered in brown fibers and fan-shaped, glossy green leaves. It thrives in bright, indirect light and requires consistent moisture and occasional fertilization to maintain its tropical appearance indoors."
  },
  {
    id: 113,
    plant_name: "Red Sandalwood Plant (Pterocarpus santalinus)",
    type: "outdoor",
    description: "Red Sandalwood is a small tree prized for its aromatic heartwood, which is used in perfumes, incense, and traditional medicine. It has compound leaves and small, inconspicuous flowers.",
    best_for: "Outdoor gardens and aromatic wood production",
    highlight: "Aromatic heartwood and compound leaves",
    care_tips: {
      light: "Prefers full sun to partial shade.",
      soil: "Well-draining, sandy soil.",
      water: "Moderate watering; allow soil to dry out slightly between waterings.",
      temperature: "Thrives in warm climates.",
      humidity: "Tolerates varying humidity levels.",
      toxicity: "Non-toxic to humans and pets.",
      tip: [
        "Protect from frost and cold drafts.",
        "Prune lightly to shape the tree.",
        "Fertilize in spring and summer with balanced fertilizer."
      ]
    },
    long_description: "Pterocarpus santalinus, known as Red Sandalwood, is a small tree valued for its aromatic heartwood used in perfumes, incense, and traditional medicine. It thrives in outdoor gardens with sandy, well-draining soil and benefits from moderate watering and occasional fertilization to maintain its aromatic properties."
  },
  {
    id: 114,
    plant_name: "Radermachera Plant (Radermachera sinica)",
    type: "indoor",
    description: "Radermachera, also known as China Doll Plant, is a tropical shrub with glossy, dark green leaves that are finely divided like a fern. It has a bushy growth habit and can be grown as a houseplant.",
    best_for: "Indoor spaces with bright, indirect light",
    highlight: "Glossy, dark green leaves and bushy growth habit",
    care_tips: {
      light: "Prefers bright, indirect light. Avoid direct sun.",
      soil: "Well-draining potting mix.",
      water: "Keep soil evenly moist during the growing season. Reduce watering in winter.",
      temperature: "Thrives in temperatures between 18-24°C (65-75°F).",
      humidity: "Prefers moderate to high humidity levels.",
      toxicity: "Mildly toxic if ingested. Keep away from pets and children.",
      tip: [
        "Prune regularly to maintain shape.",
        "Rotate the plant occasionally for even growth.",
        "Fertilize monthly during the growing season with diluted fertilizer."
      ]
    },
    long_description: "Radermachera sinica, also known as China Doll Plant, is a tropical shrub admired for its glossy, dark green leaves that resemble a fern's foliage. It thrives indoors in bright, indirect light and requires consistent moisture and occasional pruning to promote bushy growth and maintain its ornamental value."
  }

]


export default Indoor;


