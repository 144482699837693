import React from 'react'
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import PlantDetail from '../PlantDetail/PlantDetail';
import { useParams } from 'react-router-dom';
import IndoorData from '../../Assets/Indoor';
import OutdoorData from '../../Assets/OutDoor';
import { slugify } from '../../utils/slugify';



const Product = () => {
    const { type, slug } = useParams();
    // const productId = parseInt(id, 10);
    let product;

    if (type === 'indoor') {
        product = IndoorData.find(item => slugify(item.plant_name) === slug);
    } else if (type === 'outdoor') {
        product = OutdoorData.find(item => slugify(item.plant_name) === slug);
    }

    if (!product) {
        return <div>Plant not found</div>;
    }

  return (
    <div>
        <Breadcrumb product={product}/>
        <PlantDetail product={product} type={type}/>
    </div>
  )
}

export default Product
