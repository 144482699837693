import React from 'react';
import './error.css';

const Error = () => {
  return (
    <div className='error-container'>
      <h1 className='error-title'>404</h1>
      <p className='error-message'>Page Not Found</p>
      <a href="/" className='home-link'>Go to Homepage</a>
    </div>
  );
};

export default Error;
