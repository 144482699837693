import React, { useState } from 'react'
import OutDoor from '../../Assets/OutDoor'
import CardDetails from '../Cards/CardDetails'
import { Form, InputGroup } from 'react-bootstrap';

const OutdoorCom = () => {
  const [outdoorVisibleCard, setOutdoorVisibleCard] = useState(4);
  const [search, setSearch] = useState('')

  const handleOutdoorVisible = () => {
    setOutdoorVisibleCard((prev) => prev + 4);
  };

  const filteredData = OutDoor.filter((item) => {
    return search.toLowerCase() === '' ? true : item.plant_name.toLowerCase().includes(search.toLowerCase())
  })

  return (
    <div className='container'>
      <InputGroup className='my-3'>
        <Form.Control
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Plants"
        />
      </InputGroup>
      {filteredData.length > 0 ? (
        filteredData.slice(0, outdoorVisibleCard).map((item) =>
          <CardDetails
            key={item.id}
            id={item.id}
            image={item.image}
            plant_name={item.plant_name}
            description={item.long_description}
            type={item.type}
          />
        )
      ) : (
        <h4>Plant Name "{search}" is Not Available</h4>
      )}
      {filteredData.length > outdoorVisibleCard && (
        <button className='btn btn-success' onClick={handleOutdoorVisible}>Load More</button>
      )}
    </div>
  )
}

export default OutdoorCom
