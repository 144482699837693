import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils/slugify';

const Card = (props, plant_name) => {
  // const slug = slugify(plant_name);
  return (
    <div className="card" style={{ width: '18rem' }}>
      <img src={props.image} className="card-img-top" alt={props.plant_name} style={{ width: '285px', height: '285px' }} />
      <div className="card-body">
        <h5 className="card-title">{props.plant_name}</h5>
        <Link to={`/plant/${props.type}/${slugify(props.plant_name)}`} className="btn btn-success">Read More About {props.plant_name}</Link>
      </div>
    </div>
  );
};

export default Card;
